<template>
    <div class="delivery-display">
        <p class="nbs">
            <span class="is-size-6" v-html="del.expectedDateString"></span>
        </p>
        <p class="">
            <strong>{{del.name}}</strong><br />
            &pound; {{del.deliveryPriceIncVat.toFixed(2)}} 
            <small v-if="del.deliverySavingAmountIncVat > 0">(save &pound;{{del.deliverySavingAmountIncVat.toFixed(2)}})</small>
        </p>
    </div>
</template>

<script>
    //import axios from 'axios'
    export default {
        props: {
            delivery: Object
        },
        name: 'DeliveryDisplay',
        data() {
            return {
                del: Object.assign({}, this.delivery),
            }
        },
        methods: {
            
        }
    }
</script>
<style>
    .nbs { white-space: nowrap; }
</style>
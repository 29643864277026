import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bulma/css/bulma.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'


axios.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        const originalRequest = error.config
        let redir = originalRequest.url
        if (
            error.response && error.response.status &&
            error.response.status === 401 //&&
            //originalRequest.url === `${axios.defaults.baseURL}/api/token`
        ) {
            // TO DO: deal with redirect to /:key  -  i.e. logged in with email and code
            if (redir.indexOf('api/') > -1) {
                router.push(`/login?redirect=${router.currentRoute.value.query.redirect || router.currentRoute.value.path}`)
           } else {
                router.push(`/login?redirect=${redir}`)
            }
            return Promise.reject(error)
        }
        /*
        // TODO: deal with no auth existing when landing on auth required page with no previous auth
        if (error.response && error.response.status && error.response.status === 401 && !originalRequest._retry) { // && originalRequest.url.indexOf('emailtoken')==-1
            originalRequest._retry = true
            const refreshToken = store.state.account.auth.refreshToken
            const accesstoken = store.state.account.auth.accessToken
            return axios
                .post('/api/refreshtoken', {
                    refreshToken: refreshToken,
                    accessToken: accesstoken
                })
                .then(res => {
                    if (res.status === 200) {
                        const auth = res.data;
                        axios.defaults.headers.common["Authorization"] = `Bearer ${auth.accessToken
                            }`
                        store.commit("account/loginSuccess", auth)
                        Cookie.set("AUTH", JSON.stringify(auth))
                        return axios(originalRequest)
                    }
                })
        }
        return Promise.reject(error)
        */
    }
)

axios.interceptors.request.use(request => {
    // add auth header with jwt if account is logged in and request is to the api url
    const user = localStorage.user;
    const token = user ? JSON.parse(user).token : null;

    if (token) {
        request.headers.common.Authorization = `Bearer ${token}`;
    }

    return request;
});

const app = createApp(App)

app.use(router).mount('#app')

app.config.globalProperties.$adminhost = 'https://admin.colourtheirday.com'
app.config.globalProperties.$host = 'https://www.colourtheirday.com'


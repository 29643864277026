<template>
    <span class="yes-no-blob" :class="{yes: value}"></span>
</template>

<script>
    export default {
        name: 'YesNoBlob',
        props: ['value'],
        data() {
            return {
            }
        },
        components: {
            
        },
        methods: {

        }
    }
</script>

<style>
    .yes-no-blob {
        width: 1.5rem; height: 1.5rem;
        border-radius: 100%;
        line-height:0;
        background-color: #c14e4e;
        display: inline-block;
        margin:0.5rem;
    }
    .yes-no-blob.yes {
        background-color: #6db342;
    }
</style>

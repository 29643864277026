<template>
    <header class="navbar is-fixed-top has-background-light not-printed">
        <div class="navbar-brand">
            <a role="button" @click="isactive=!isactive" :class="{'is-active': isactive === true}" class="navbar-burger" aria-label="menu" aria-expanded="false">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
        </div>
        <div class="navbar-menu" :class="{'is-active': isactive === true}">
            <div class="navbar-start">
                <router-link to="/" class="navbar-item">Home</router-link>
                <search v-model="modelValue"></search>
            </div>
            <div class="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">Sales</a>
                    <div class="navbar-dropdown">
                        <router-link to="/Orders" class="navbar-item">Orders</router-link>
                        <router-link to="/Users" class="navbar-item">Users</router-link>
                    </div>
                </div>
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">Catalogue</a>
                    <div class="navbar-dropdown">
                        <router-link to="/Products" class="navbar-item">Products</router-link>
                        <router-link to="/Categories" class="navbar-item">Categories</router-link>
                        <router-link to="/Personalisations" class="navbar-item">Personalisations</router-link>
                        <router-link to="/Orders" class="navbar-item">Stock Management</router-link>
                    </div>
                </div>
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">Promotions</a>
                    <div class="navbar-dropdown">
                        <router-link to="/Offers" class="navbar-item">Offers</router-link>
                        <router-link to="/PromoCodes" class="navbar-item">PromoCodes</router-link>
                        <router-link to="/ClearOfferCache" class="navbar-item">Clear Offer Cache</router-link>
                    </div>
                </div>
                <router-link to="/Login" class="navbar-item">Login</router-link>
            </div>
        </div>
    </header>
</template>


<style>

</style>
<script>
    import Search from './Search.vue'

    export default {
        name: "NavMenu",
        props: ['modelValue'],
        emits: ['update:modelValue', 'search'],
        components: {
            Search
        },
        watch: {
            modelValue(nv) {
                this.$emit('search', nv)
            }
        },
        data() {
            return {
                isactive: false
            }
        },
        methods: {
            collapse() {
                this.isExpanded = false;
            },

            toggle() {
                this.isExpanded = !this.isExpanded;
            }
        }
    }
</script>
<template>
    <p class="is-size-6">
        {{add.firstName}} {{add.lastName}} <br />
        <template v-if="add.businessName">{{add.businessName}}<br /></template>
        <template v-if="add.address1">{{add.address1}}<br /></template>
        <template v-if="add.address2">{{add.address2}}<br /></template>
        <template v-if="add.address3">{{add.address3}}<br /></template>
        <template v-if="add.town">{{add.town}}<br /></template>
        <template v-if="add.county">{{add.county}}<br /></template>
        <template v-if="add.postCode">{{add.postCode}}<br /></template>
        <template v-if="add.country">{{add.country.name}}<br /></template>
    </p>
</template>

<script>
    import axios from 'axios'
    export default {
        props: {
            address: Object
        },
        name: 'AddressDisplay',
        data() {
            return {
                add: Object.assign({}, this.address),
            }
        },
        methods: {
            saveaddress() {
                axios.get();
            }
        }
    }
</script>
<template>
    <div class="field search-control mb-0 has-addons">
        <div class="control">
            <input class="input" 
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                incremental 
                placeholder="Search..." type="search" />
        </div>
        <p class="control">
            <button @click="$router.push('/Products')" class="button is-primary">Go</button>
        </p>
    </div>
</template>

<script>
    //v - on: search = "dosearch" 
    import axios from 'axios'
    export default {
        props: ['modelValue'],
        emits: ['update:modelValue'],
        name: 'Search',
        methods: {
        }
    }
</script>

<style>
    .search-control {
        padding: 0.5rem 1rem;
    }
</style>

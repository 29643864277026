<template>
    <div class="container order-edit content">
        <div class="columns">
            <div class="column">
                <div class="columns">
                    <div class="column"><strong>{{ord.appUser.firstName}} {{ord.appUser.lastName}}</strong></div>
                    <div class="column has-text-right">
                        {{ord.order_reference.substr(0, 11)}}
                        &nbsp;
                        £ {{total.toFixed(2)}}
                        <strong> 
                        {{printdate(ord.order_datetime)}}
                        </strong>
                    </div>
                </div>
                <div v-for="ln in ord.basket.basketItems" class="columns order-line is-striped">
                    <div class="column is-narrow">
                        <img width="64" height="64" :src="`http://www.colourtheirday.com/images/product/${ln.mainImage.slug}?width=128&format=webp`" />
                    </div>
                    <div class="column is-narrow is-size-3">x {{ln.quantity}}</div>
                    <div class="column">
                        <strong v-html="ln.sku.product.name"></strong>
                        <p>
                            <div v-for="(ao, i) in ln.sku.skuOptions" :key="i">
                                {{ao.attributeOption.attribute.label}}: {{ao.attributeOption.option.label}}
                            </div>
                        </p>
                        <p v-if="ln.basketItemPersonalisations.length > 0">
                            <div v-for="(p, i) in ln.basketItemPersonalisations" :key="i">
                                {{p.name}}: {{p.value}}
                            </div>
                        </p>
                    </div>
                    <div class="column is-narrow">£ {{ln.lineTotalIncVat.toFixed(2)}}</div>
                </div>

            </div>
            <div class="column is-narrow">
                <div>Delivery to:</div>
                <address-display :address="ord.deliveryAddress"></address-display>
                <!--<address-display :address="ord.invoiceAddress"></address-display>-->
            </div>
            <div class="column is-narrow">
                <div>Expected:</div>
                <delivery-display :delivery="ord.orderedDeliveryChoice"></delivery-display>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import AddressDisplay from './AddressDisplay.vue'
    import DeliveryDisplay from './DeliveryChoiceDisplay.vue'
    /* eslint-disable */
    //import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            order: Object
        },
        components: { AddressDisplay, DeliveryDisplay },
        data() {
            return {
                ord: Object.assign({}, this.order),
            }
        },
        computed: {
            total() {
                return this.order ? this.order.basket.basketItems
                    .filter(x => x.savedForLater == false && x.status == 1)
                    .reduce((a,b) => a + (b.priceIncVat*b.quantity), 0) : 0
            }
        },
        methods: {
            saveorder() {
                axios.get();
            },
            printdate(json) {
                let d = new Date(json)
                return d.toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: 'numeric' })
            }
        }
    }
</script>
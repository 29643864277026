<template>
    <div class="container product-edit">
        <p>{{searchterm}}</p>


        <div class="columns is-striped" v-for="(user, i) in users" :key="i">
            <div class="column is-narrow">
                #{{user.id}}
            </div>
            <div class="column">
                {{user.email}}
            </div>
            <div class="column">
                {{user.firstName}} {{user.lastName}}
            </div>
            <div class="column is-narrow">
                <span v-for="role in user.appRoles" class="tag is-large">{{role.name}} <span class="delete"></span></span>
            </div>
            <div class="column is-narrow"><button @click="$router.push(`/Users/${user.id}`)" class="button">Edit</button></div>
        </div>
        <footer class="has-background-light">
            <button class="button" @click="currentpage--" :class="{'is-disabled':currentpage==1}">Prev Page</button>
            <div class="select">
                <select v-model="currentpage">
                    <option v-for="page in Math.ceil(count/pagesize)" :value="page" :key="page">{{page}}</option>
                </select>
            </div>
            <button class="button" @click="currentpage++" :class="{'is-disabled':currentpage==Math.floor(count/pagesize)}">Next page</button>
        </footer>
    </div>
</template>
<style>
    footer {
        padding: 1.5rem;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .add-new-here {
        margin-bottom: 2rem;
    }

    .is-striped:nth-child(even) {
        background-color: #f7f7f7;
    }
</style>
<script>
    /* eslint-disable */
    import axios from 'axios'
    export default {
        name: "ProductList",
        components: {
            
        },
        props: { q: String , searchterm: String },
        data() {
            return {
                users: [],
                count: 0,
                currentpage: 1,
                filter: { published: null },
                pagesize: 30,
                insearch: false,
                imgbase: `${this.$host}/images/product/`,
                uploadurl: this.$host,
                roles: []
            }
        },
        methods: {
            dosearch() {
                if (this.searchterm.length > 2) {
                    axios.get('/api/users/search?q=' + this.searchterm)
                        .then((response) => {
                            this.users = response.data.model
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                else {
                    //this.$emit('search-reset')
                }
            },
            fetchusers() {

                axios.get('/api/users?t=' + this.pagesize + '&p=' + this.currentpage)
                    .then((response) => {
                        if (response && response.data && response.data.model) {
                            this.users = response.data.model;
                            this.count = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchroles() {
                axios.get('/api/users/roles')
                    .then((response) => {
                        this.roles = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
        watch: {
            currentpage() {
                this.fetchusers()
            },
            searchterm(nv) {
                if (nv.length == 0)
                    this.fetchusers()
                else
                    this.dosearch()
            }
        },
        mounted() {
            if (this.searchterm.length)
                this.dosearch()
            else
                this.fetchusers()

            this.fetchroles()
        },
        computed: {

        }
    }
</script>
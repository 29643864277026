<template>
    <div class="offer-edit">
        <div class="columns">

            <div class="column is-one-third content">
                <h2>Details</h2>
                <div class="field">
                    <div class="columns">
                        <div class="column is-narrow">
                            <label class="label">Status</label>
                            <div class="select is-fullwidth">
                                <select v-model="off.status">
                                    <option :value="0">Hidden</option>
                                    <option :value="1">Active</option>
                                    <option :value="-1">Deleted</option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <label class="label">Offer Type</label>
                            <div class="select is-fullwidth">
                                <select v-model="off.offerType">
                                    <option v-for="(typ, ind) in types" :value="ind" :key="ind">{{typ.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <label class="label">ID</label>
                            {{off.id}}
                        </div>
                        <!-- NotSet = 0, Standard = 1, SpecialOrder = 2, Clearance = 3 -->
                    </div>
                </div>
                <div class="field">
                    <label class="label">Admin Name</label>
                    <input v-model="off.name" class="input" />
                </div>
                <div class="field">
                    <div class="columns">
                        <div  class="column ">
                            <label class="label">Valid From</label>
                            <input type="datetime-local" class="input is-small" v-model="off.startDate" />
                        </div>
                        <div  class="column">
                            <label class="label">Until</label>
                            <input type="datetime-local" class="input is-small" v-model="off.endDate" />
                        </div>

                    </div>
                </div>
                <div class="field">
                    <label class="label">Headline</label>
                    <input v-model="off.headline" class="input" />
                </div>
                <div class="field">
                    <label class="label">Description</label>
                    <textarea class="textarea" v-model="off.description" rows="3"></textarea>
                </div>
                <div class="field">
                    <label class="label">Icon Text</label>
                    <input v-model="off.iconText" class="input" />
                </div>
                <div class="field columns">
                    <div class="column">
                    <label class="label">Combine with other Offers</label>
                    <div class="select is-fullwidth">
                        <select v-model="off.allowInCombination">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div class="field columns">
                    <div class="column">
                    <label class="label">Importance <small style="font-weight: normal">(higher number applies first)</small></label>
                        <input v-model="off.precedence" type="number" class="input" />
                    </div>
                </div>
                <div class="field columns">
                    <div class="column">
                    <label class="label">Display Sequence</label>
                        <input v-model="off.sequence" type="number" class="input" />
                    </div>
                </div>
                <div class="field columns">
                    <div class="column">
                    <label class="label">Max Uses Per Order</label>
                        <input v-model="off.maxUsesPerOrder" type="number" class="input" />
                    </div>
                </div>
                <div class="field columns">
                    <div class="column">
                    <label class="label">Display Type</label>
                    <div class="select is-fullwidth">
                        <select v-model="off.display_type">
                            <option :value="1">Normal - Show Savings</option>
                            <option :value="2">Hidden Discount</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Special Expiry Terms</label>
                    <textarea class="textarea" v-model="off.expiryTerms" rows="3"></textarea>
                </div>
            </div>
            <div class="column content">
                <h2>Product Rules..</h2>
                <div v-for="(q, i) in off.offerQualifiers" :key="i" class="offerqualifier">
                    <div class="box mb-4">
                        <div class="columns">
                            <div class="column is-narrow">
                                <label class="label">ID</label>
                                {{q.id}}
                            </div>
                            <div class="column is-one-quarter">
                                <label class="label">Required Products</label>
                                <input class="input" type="number" min="0" v-model="q.requiredQuantity" />
                            </div>
                            <div class="column is-one-quarter">
                                <label class="label">Discounted Products</label>
                                <input class="input" type="number" min="0" v-model="q.discountedQuantity" />
                            </div>
                            <div class="column" v-if="showdiscount.includes(off.offerType)">
                                <label class="label">{{types[off.offerType].valueLabel}}</label>
                                <input class="input" type="number" min="0" v-model="q.discountValue" />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <label class="label">Product Match Type</label>
                                <div class="select is-fullwidth">
                                    <select v-model="q.assureBoolean">
                                        <option :value="true">IS any</option>
                                        <option :value="false">NOT any</option>
                                    </select>
                                </div>
                            </div>
                            <div class="column">
                                <label class="label">Apply Discount on</label>
                                <div class="select is-fullwidth">
                                    <select v-model="q.cheapestFirst">
                                        <option :value="true">Cheapest FIRST</option>
                                        <option :value="false">Expensive FIRST</option>
                                    </select>
                                </div>
                            </div>
                            <div class="column">
                                <label class="label">Stock Type</label>
                                <div class="select is-fullwidth">
                                    <select v-model="q.stockType">
                                        <option :value="0">Any</option>
                                        <option :value="1">Standard</option>
                                        <option :value="2">Special Order</option>
                                        <option :value="3">Clearance</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="q-prods columns">
                                <div class="column is-one-third"><label class="label">Qualifying Products</label></div>
                                <div class="column">
                                    <div class="columns">
                                        <div class="column">
                                            <template v-if="q.qualifyingProducts.length == 0">
                                                <span class="tag">ANY</span>
                                            </template>
                                            <div v-else class="tags box">
                                                <span class="tag is-medium is-warning max-size-20" v-for="(qp, i) in q.qualifyingProducts" :key="i">
                                                    {{qp.product.name}}
                                                    <small>#{{qp.productId}}</small> <button @click="deleteproductfromqualifier(q, qp)" class="delete"></button>
                                                </span>
                                            </div>
                                            <div v-if="editprodqualifier == q" class="search-here">
                                                <div class="field has-addons is-fullwidth">
                                                    <p class="control is-expanded"><input class="input" incremental v-on:search="searchproducts" placeholder="Search..." type="search" v-model="qprod" /></p>
                                                    <p class="control"><button @click="editprodqualifier = null" class="button">Done</button></p>
                                                </div>

                                                <div v-if="products.length" class="results">
                                                    <div @click="addproducttoqualifier(q, p)" v-for="(p, i) in products" :key="i" class="results-item">
                                                        <img loading="lazy" v-if="p.productImages.length" width="32" :src="`https://www.colourtheirday.com/images/product/${p.productImages[0].slug}?format=webp&width=64`" />
                                                        {{p.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-narrow"><button @click="editprodqualifier = q" class="button w6 is-small">Add Prod</button></div>
                                    </div>
                                </div>
                            </div>
                            <div class="q-cats columns">
                                <div class="column is-one-third"><label class="label">Qualifying Categories</label></div>
                                <div class="column">
                                    <div class="columns">
                                        <div class="column">
                                            <template v-if="q.qualifyingCategories.length == 0">
                                                <span class="tag">ANY</span>
                                            </template>
                                            <div v-else class="tags box">
                                                <span class="tag is-medium is-warning" v-for="(qc, i) in q.qualifyingCategories" :key="i">
                                                    {{qc.category.name}} #{{qc.category.id}}
                                                    <button @click="deletequalifyingcat(qc.category.id, q)" class="delete"></button>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="column is-narrow"><button @click="fetchcategories(q)" class="button w6 is-small">Add Cat</button></div>
                                    </div>

                                </div>
                            </div>
                            <div class="q-options columns">
                                <div class="column is-one-third"><label class="label">Qualifying Attributes</label></div>
                                <div class="column">
                                    <div class="columns">
                                        <div class="column">
                                            <template v-if="q.offerOptionQualifiers.length == 0">
                                                <span class="tag">ANY</span>
                                            </template>
                                            <div class="box" v-for="(oq, i) in q.offerOptionQualifiers" :key="i">
                                                <div class="field is-horizontal">
                                                    <div class="field-label is-normal">
                                                        <label class="label no-wrap">Match Type #{{oq.id}}</label>
                                                    </div>
                                                    <div class="field-body">
                                                        <div class="select is-small">
                                                            <select v-model="oq.assureBoolean">
                                                                <option :value="true">IS</option>
                                                                <option :value="false">IS NOT</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="field tags">
                                                    <span class="tag is-medium is-warning" v-for="(ao, i) in oq.qualifyingOptions" :key="i">
                                                        {{ao.attributeOption.attribute.label}}:&nbsp;<strong>{{ao.attributeOption.option.label}}</strong>&nbsp;<small>#{{ao.attributeOption.id}}</small>
                                                        <button @click="deleteAttributeOptionFromGroup(ao.attributeOption, oq)" class="delete"></button>
                                                    </span>



                                                    <button @click="editgroup = oq" v-if="editgroup != oq" class="button is-small ">Add Option</button>
                                                </div>
                                                <div v-if="editgroup == oq">
                                                    <p class="control">
                                                        <span class="select is-fullwidth">
                                                            <select @change="attributeOptionToAdd = null;attributeOptionToCreate = null" v-model="attributeToAdd">
                                                                <option :value="null" disabled>Select Attribute...</option>
                                                                <option v-for="(at,i) in attributes.sort(function(a, b) { return a.name.localeCompare(b.name);})" :key="i" :value="at">{{at.name}}</option>
                                                            </select>
                                                        </span>
                                                    </p>
                                                    <p v-if="attributeToAdd != null && attributeOptionToCreate == null" class="control">
                                                        <span class="select is-fullwidth">
                                                            <select v-model="attributeOptionToAdd">
                                                                <option :value="null" disabled>Select Option...</option>
                                                                <option v-for="(ao,i) in attributeToAdd.attributeOptions.sort(function(a, b) { return a.option.label.localeCompare(b.option.label);})" :key="i" :value="ao">{{ao.option.label}}</option>
                                                            </select>
                                                        </span>
                                                    </p>
                                                    <p v-if="attributeOptionToCreate != null" class="control">
                                                        <input :ref="`${oq.id}_opt`" class="input" v-model="attributeOptionToCreate.option.label" />
                                                    </p>
                                                    <template v-if="attributeToAdd != null">
                                                        <p v-if="attributeOptionToCreate == null" class="control">
                                                            <a @click="creatNewAttributeOption" class="button">NEW Option</a>
                                                        </p>
                                                        <p v-else class="control">
                                                            <a @click="addNewAttributeOptionToAttribute" class="button is-primary">ADD</a>
                                                        </p>
                                                    </template>
                                                    <p v-if="attributeOptionToAdd != null" class="control">
                                                        <a @click="addAttributeOptionToGroup" :class="{'is-disabled': attributeToAdd==null||attributeOptionToAdd == null}" class="button is-primary">Add to Product</a>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="column is-narrow">
                                            <button @click="addqgroup(q)" class="button w6 is-small">Add Group</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="q-skus columns">
                                <div class="column is-one-third"><label class="label">Qualifying Skus</label></div>
                                <div class="column">
                                    <div class="columns">
                                        <div class="column">
                                            <template v-if="q.qualifyingSkus.length == 0">
                                                <span class="tag">ANY</span>
                                            </template>
                                            <div v-else class=" tags box">
                                                <span class="tag is-medium is-warning" v-for="(qc, i) in q.qualifyingSkus" :key="i">{{qc.sku.name}}</span>
                                            </div>
                                        </div>
                                        <div class="column is-narrow">
                                            <button class="button w6 is-small">Add Sku</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="has-text-centered  is-size-5 or-promo and-qual" v-if="i < off.offerQualifiers.length - 1">AND</p>
                </div>
                <button @click="addqualifier" class="button">Add Product Rule</button>


                <h2>Profile Rules</h2>
                <div class="box offerprofile">
                    <div v-for="(opq, i) in off.offerProfileQualifiers" :key="i">
                        <div class="columns">
                            <div class="column">
                                <label class="label">Id</label>
                                #{{opq.id}}
                            </div>
                            <div class="column">
                                <label class="label">Valuation Time Period <small>(in days)</small></label>
                                <input class="input" v-model="opq.valueProviderCalculationPeriod" type="number" />
                            </div>
                            <div class="column">
                                <label class="label">Profile Match Type</label>
                                <div class="select is-fullwidth">
                                    <select v-model="opq.assureBoolean">
                                        <option :value="true">HAS any</option>
                                        <option :value="false">Does NOT Have</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="columns mt-6">
                            <div class="column is-one-third">
                                <label class="label">Qualifying Profile Actions</label>
                            </div>
                            <div class="column">
                                <div class="columns" v-for="(qpa, i) in opq.qualifyingProfileActions" :key="i">
                                    <div class="box">
                                        <div class="columns">
                                            <div class="column">
                                                <label class="label">Id</label>
                                                #{{qpa.id}}
                                            </div>
                                            <div class="column">
                                                <label class="label">Active Period</label>
                                                <input class="input" v-model="qpa.calculationPeriod" />
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column">
                                                <label class="label">Min Required</label>
                                                <input class="input" v-model="qpa.minValueRequired" />
                                            </div>
                                            <div class="column">
                                                <label class="label">Max Required</label>
                                                <input class="input" v-model="qpa.maxValueRequired" />
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column">
                                                <label class="label">Cost on Redemption</label>
                                                <input class="input" v-model="qpa.costOnRedemption" />
                                            </div>
                                            <div class="column">
                                                <label class="label">Is Expiration Provider</label>
                                                <div class="select is-fullwidth">
                                                    <select v-model="qpa.isExpirationProvider">
                                                        <option :value="true">Yes</option>
                                                        <option :value="false">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column">
                                                <div class="box">
                                                    <label class="label">Action Required</label>
                                                    <div class="columns mb-0">
                                                        <div class="column label has-text-right">Name</div>
                                                        <div class="column">{{qpa.profileAction.name}} (#{{qpa.profileAction.id}})</div>
                                                    </div>
                                                    <div class="columns mb-0">
                                                        <div class="column label has-text-right">Description</div>
                                                        <div class="column">{{qpa.profileAction.description}}</div>
                                                    </div>
                                                    <div class="columns mb-0">
                                                        <div class="column label has-text-right">Key</div>
                                                        <div class="column">{{qpa.profileAction.key}}</div>
                                                    </div>
                                                    <div v-if="qpa.displayInfo" class="columns mb-0">
                                                        <div class="column label has-text-right">Display Info</div>
                                                        <div class="column">{{qpa.profileAction.displayInfo}}</div>
                                                    </div>
                                                    <div v-if="qpa.displayLabel" class="columns">
                                                        <div class="column label has-text-right">Display Label</div>
                                                        <div class="column">{{qpa.profileAction.displayLabel}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2>Promocode Rules</h2>
                <div class="offerprofile">
                    <div v-for="(opc, i) in off.offerPromoCodeQualifiers" :key="i">
                        <div class="box mb-4">
                            <div class="columns">
                                <div class="column label"> Required Promocodes (#{{opc.id}})</div>
                                <div class="column">
                                    <div v-for="(qp, i) in opc.qualifyingPromoCodes" class="" :key="i">
                                        <div class="box is-relative">
                                            <strong>{{qp.promoCode.string}}</strong><br />
                                            <small>{{qp.promoCode.name}}</small>
                                            <button @click="deletepromofromqualifier(opc, qp)" class="delete delpromo"></button>
                                        </div>
                                        <p class="or-promo has-text-centered  is-size-5" v-if="i < opc.qualifyingPromoCodes.length - 1">OR</p>
                                    </div>
                                    <div class="field has-addons mt-4" v-if="editpromoqualifier == opc">
                                        <p class="control">
                                            <div class="select is-fullwidth">
                                                <select v-model="promoToAdd">
                                                    <option>Select Promocode</option>
                                                    <option :value="pc" v-for="(pc, i) in promocodes" :key="i">{{pc.string}} - {{pc.name}}</option>
                                                </select>
                                            </div>
                                        </p>
                                        <p class="control">
                                            <button @click="addpromotoqualifier" :disabled="promoToAdd==null" class="button is-primary">Add to Offer</button>
                                        </p>
                                    </div>
                                </div>
                                <div class="column is-narrow"><button @click="fetchpromocodes(opc)" class="button is-small">Add</button></div>
                                <div class="column is-narrow"><button @click="deletepromoqualifier(opc)" class="delete"></button></div>
                            </div>
                        </div>
                        <p class="and-qual  has-text-centered  is-size-5" v-if="i < off.offerPromoCodeQualifiers.length - 1">AND</p>
                    </div>
                    <button @click="addpromoqualifier" class="button">Add PromoCode Rule</button>
                </div>

                <h2>Delivery Rules</h2>
                <div class="offerprofile">
                    <div v-for="(dppq, i) in off.deliveryPricePolicyQualifiers" :key="i">
                        <div class="box mb-4">
                            <div class="columns">
                                <div class="column is-half mb-4">
                                    <label class="label">Match Type</label>
                                </div>
                                <div class="column">
                                    <div class="select is-fullwidth">
                                        <select v-model="dppq.assureBoolean">
                                            <option :value="true">IS any</option>
                                            <option :value="false">NOT any</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="column is-narrow"><button @click="deletepricetierqualifier(dppq)" class="delete"></button></div>
                            </div>
                            <div class="columns">
                                <div class="column label is-half">Required PriceTiers</div>
                                <div class="column">
                                    <div v-for="(qpt, i) in dppq.qualifyingPriceTiers" class="" :key="i">
                                        <div class="box is-relative">
                                            <strong>{{qpt.priceTier.name}}</strong>
                                            <button @click="deletepricetierfromqualifier(dppq, qpt)" class="delete delpromo"></button>
                                        </div>
                                        <p class="or-promo has-text-centered  is-size-5" v-if="i < dppq.qualifyingPriceTiers.length - 1">OR</p>
                                    </div>
                                    <div class="field has-addons mt-4" v-if="editpricetierqualifier == dppq">
                                        <p class="control">
                                            <div class="select is-fullwidth">
                                                <select v-model="priceTierToAdd">
                                                    <option :value="null">Select PriceTier</option>
                                                    <option v-for="(pt, i) in pricetiers" :value="pt" :key="i">{{pt.name}}</option>

                                                </select>
                                            </div>
                                        </p>
                                        <p class="control">
                                            <button @click="addpricetiertoqualifier" :disabled="priceTierToAdd==null" class="button is-primary">Add to Offer</button>
                                        </p>
                                    </div>
                                </div>
                                <div class="column is-narrow"><button @click="editpricetierqualifier = dppq" class="button is-small">Add</button></div>
                            </div>

                            <div class="columns mt-4">
                                <div class="column is-half label">Required Delivery Destinations</div>
                                <div class="column">
                                    <div v-for="(qpt, i) in dppq.qualifyingDestinations" class="" :key="i">
                                        <div class="box is-relative">
                                            <strong>{{qpt.destination.name}}</strong>
                                            <button @click="deletedestinationfromqualifier(dppq, qpt)" class="delete delpromo"></button>
                                        </div>
                                        <p class="or-promo has-text-centered  is-size-5" v-if="i < dppq.qualifyingDestinations.length - 1">OR</p>
                                    </div>
                                    <div class="field has-addons mt-4" v-if="editdestinationqualifier == dppq">
                                        <p class="control">
                                            <div class="select is-fullwidth">
                                                <select v-model="destinationToAdd">
                                                    <option :value="null">Select Destination</option>
                                                    <option v-for="(pt, i) in destinations" :value="pt" :key="i">{{pt.name}}</option>

                                                </select>
                                            </div>
                                        </p>
                                        <p class="control">
                                            <button @click="adddestinationtoqualifier" :disabled="destinationToAdd==null" class="button is-primary">Add to Offer</button>
                                        </p>
                                    </div>
                                </div>
                                <div class="column is-narrow"><button @click="fetchdestinations(dppq)" class="button is-small">Add</button></div>

                            </div>
                        </div>
                        <p class="and-qual  has-text-centered  is-size-5" v-if="i < off.deliveryPricePolicyQualifiers.length - 1">AND</p>
                    </div>
                    <button @click="addpricetierqualifier" class="button">Add Delivery Rule</button>
                </div>
            </div>
        </div>

        <footer class="has-background-light">
            <div class="columns">
                <div class="buttons">
                    <button class="button" @click="$emit('cancel-save')">Cancel</button>
                    <button class="button is-primary" @click="saveoffer">SAVE</button>
                    <button style="margin-left:auto;" class="button" @click="cloneoffer(off.id)">Duplicate</button>
                </div>
            </div>
        </footer>

        <div :class="{'is-active': showcats==true}" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <div class="modal-card-head">
                    <p class="modal-card-title">Add to Categories</p>
                    <button @click="showcats=false" class="modal-close is-large" aria-label="close"></button>
                </div>
                <div class="modal-card-body">
                    <ul class="content">
                        <li  v-for="(c,i) in categories" :key="i">
                            <label class="cat-name"><input type="checkbox" :value="c.id" v-model="addingcats" /> {{c.name}}</label>
                            <ul v-if="c.children.length">
                                <li v-for="(cc,i) in c.children" :key="i" >
                                    <label class="cat-name"><input type="checkbox"  :value="cc.id" v-model="addingcats" /> {{cc.name}}</label>
                                    <ul v-if="cc.children.length">
                                        <li v-for="(ccc,i) in cc.children" :key="i" >
                                            <label class="cat-name"><input type="checkbox"  :value="ccc.id" v-model="addingcats" /> {{ccc.name}}</label>
                                            <ul v-if="ccc.children.length">
                                                <li v-for="(cccc,i) in ccc.children" :key="i" >
                                                    <label class="cat-name"><input type="checkbox"  :value="cccc.id" v-model="addingcats" /> {{cccc.name}}</label>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul> 
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <footer class="modal-card-foot">
                    <button @click="showcats=false" class="button">Cancel</button>
                    <button @click="processaddingcats" :disabled="addingcats.length==0" class="button is-success">Add Selection</button>
                </footer>
            </div>
        </div>

    </div>
</template>
<style>
    .offer-qualifier {  }
    .no-wrap { white-space: nowrap; }
    .button.w6 { width: 6rem; }
    .delpromo { position: absolute; right: 1rem; top: 1rem; }
    .or-promo { 
        margin-top: -1rem;
        margin-bottom: 0.75rem;
    }
    .and-qual { margin-bottom: 0.75rem; }
    .search-here { position: relative; }
    .results { 
        position: absolute; top: 2.75rem; background: #fff; padding: 0.75rem; width: 100%; z-index: 10; 
        box-shadow: 6px 17px 20px 0rem #00000070;
        border-radius: 4px;  
        max-height: 50vh;
        overflow: auto;
    }
    .results-item { 
        padding: 0.25rem;
        display: flex;
        align-items: center; 
        cursor: pointer;

    }
    .results-item:hover { background: #f7f7f7; }
    .results-item img { margin-right: 0.75rem; }
    .tag.max-size-20 { overflow: auto; }
</style>
<script>
    import axios from 'axios'
    import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            offer: Object,
            types: Array
        },
        emits: ['cancelSave', 'doSave'],
        components: {
            YesNoBlob, BoolTick
        },
        data() {
            return {
                off: Object.assign({}, this.offer),
                stocktypes: [
                    'NotSet', 'Standard', 'SpecialOrder', 'Clearance'
                ],
                showdiscount: [1, 2, 3, 5, 6, 7, 8, 9],
                categories: [],
                promocodes: [],
                pricetiers: [
                    { id: 1, name: "Standard" }, 
                    { id: 2, name: "First Class" }, 
                    { id: 3, name: "Premium" }, 
                ],
                showcats: false,
                addingcats: [],
                editcatqualifier: null,
                editgroup: null,
                editpromoqualifier: null,
                editprodqualifier: null,
                editpricetierqualifier: null,
                editdestinationqualifier: null,
                destinations: [],
                qprod: null,
                products: [],
                promoToAdd: null,
                destinationToAdd: null,
                priceTierToAdd: null,
                attributes: [],
                attributeToAdd: null,
                attributeOptionToAdd: null,
                attributeOptionToCreate: null,
            }
        },
        computed: {
            flatcategories() {
                let fla = []
                if(this.categories && this.categories.length)
                    this.categories.forEach((v) => {
                        fla.push(v)
                        v.children.forEach((c) => {
                            fla.push(c)
                            c.children.forEach((cc) => {
                                fla.push(cc)
                                cc.children.forEach((ccc) => {
                                    fla.push(ccc)
                                    ccc.children.forEach((cccc) => {
                                        fla.push(cccc)
                                    })
                                })
                            })
                        })
                    })
                return fla
            }
        },
        mounted() {
            this.fetchAttributes()
        },
        methods: {
            saveoffer() {
                axios.post('/api/offers', this.off)
                    .then((response) => {
                        if (response && response.data) {
                            this.$emit('doSave', response.data)
                            this.off = response.data
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            addqprod(targ) {

            },
            addqcat(targ) {

            },
            addqsku(targ) {

            },
            addqgroup(targ) {
                targ.offerOptionQualifiers.push({
                    id: 0,
                    assureBoolean: true,
                    offerQualifierId: targ.id,
                    qualifyingOptions: []
                })
            },
            cloneoffer(oid) {
                axios.get(`/api/offers/clone/${oid}`)
                    .then((response) => {
                        if (response && response.data) {
                            this.$emit('doSave', response.data)
                            this.off = response.data
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            processaddingcats() {
                this.addingcats.forEach((ac) => {
                    if (!this.editcatqualifier.qualifyingCategories.find(cp => cp.categoryId == ac)) {
                        let catta = this.flatcategories.find(c => c.id == ac);
                         
                        this.editcatqualifier.qualifyingCategories.push({
                            offerQualifierId: this.editcatqualifier.id,
                            category: {
                                id: catta.id, name: catta.name,
                                parentId: catta.parentId, depth: catta.depth
                            },
                            categoryId: catta.id,
                        })
                    }
                })
                this.addingcats = []
                this.showcats = false
            },
            deletequalifyingcat(cid, q) {
                let ind = q.qualifyingCategories.findIndex(c => c.categoryId == cid)
                q.qualifyingCategories.splice(ind, 1)
            },
            addpromoqualifier() {
                this.off.offerPromoCodeQualifiers.push({
                    id: 0,
                    qualifyingPromoCodes: [],
                    code: Date.now(),
                    specialOfferId: this.off.id
                })
            },
            addqualifier() {
                this.off.offerQualifiers.push({
                    id: 0,
                    assureBoolean: true,
                    specialOfferId: this.off.id,
                    requiredQuantity: 1,
                    discountedQuantity: 1,
                    discountValue: 0,
                    cheapestFirst: false,
                    assureBoolean: true,
                    stockType: 0,
                    code: Date.now(),
                    qualifyingProducts: [],
                    qualifyingCategories: [],
                    qualifyingSkus: [],
                    offerSkuPrices: [],
                    offerOptionQualifiers:[]
                })
            },
            addpricetierqualifier() {
                this.off.deliveryPricePolicyQualifiers.push({
                    id: 0,
                    assureBoolean: true,
                    specialOfferId: this.off.id,
                    qualifyingPriceTiers: [],
                    qualifyingDestinations: [],
                    code: Date.now()
                })
            },
            deletepromoqualifier(pq) {
                let ind = this.off.offerPromoCodeQualifiers.findIndex(x => (x.code && pq.code && x.code == pq.code) || (x.id != 0 && pq.id == x.id))
                this.off.offerPromoCodeQualifiers.splice(ind, 1)
            },
            deletepricetierqualifier(pq) {
                let ind = this.off.deliveryPricePolicyQualifiers.findIndex(x => (x.code && pq.code && x.code == pq.code) || (x.id != 0 && pq.id == x.id))
                this.off.deliveryPricePolicyQualifiers.splice(ind, 1)
            },
            addpromotoqualifier() {
                if (this.editpromoqualifier != null && !this.editpromoqualifier.qualifyingPromoCodes.find(x => x.promoCode.string == this.promoToAdd.string)) {
                    this.editpromoqualifier.qualifyingPromoCodes.push({
                        id: 0,
                        offerPromoCodeQualifierId: this.editpromoqualifier.id,
                        promoCodeId: this.promoToAdd.id,
                        promoCode: this.promoToAdd
                    })
                    this.promoToAdd = null
                    this.editpromoqualifier = null
                }
            },
            addpricetiertoqualifier() {
                if (this.editpricetierqualifier != null && !this.editpricetierqualifier.qualifyingPriceTiers.find(x => x.priceTier.id == this.priceTierToAdd.id)) {
                    this.editpricetierqualifier.qualifyingPriceTiers.push({
                        id: 0,
                        deliveryPricePolicyQualifierId: this.editpricetierqualifier.id,
                        priceTierId: this.priceTierToAdd.id,
                        priceTier: this.priceTierToAdd
                    })
                    this.priceTierToAdd = null
                    this.editpricetierqualifier = null
                }
            },
            adddestinationtoqualifier() {
                if (this.editdestinationqualifier != null && !this.editdestinationqualifier.qualifyingDestinations.find(x => x.destination.id == this.destinationToAdd.id)) {
                    this.editdestinationqualifier.qualifyingDestinations.push({
                        id: 0,
                        deliveryPricePolicyQualifierId: this.editdestinationqualifier.id,
                        destinationId: this.destinationToAdd.id,
                        destination: this.destinationToAdd
                    })
                    this.destinationToAdd = null
                    this.editdestinationqualifier = null
                }
            },
            deletepromofromqualifier(q, qp) {
                this.promoToAdd = null
                this.editpromoqualifier = null
                let ind = q.qualifyingPromoCodes.findIndex(c => c == qp)
                q.qualifyingPromoCodes.splice(ind, 1)
            },
            deletepricetierfromqualifier(q, qp) {
                this.priceTierToAdd = null
                this.editpricetierqualifier = null
                let ind = q.qualifyingPriceTiers.findIndex(c => c.priceTier.id == qp.id)
                q.qualifyingPriceTiers.splice(ind, 1)
            },
            deletedestinationfromqualifier(q, qp) {
                this.destinationToAdd = null
                this.editdestinationqualifier = null
                let ind = q.qualifyingDestinations.findIndex(c => c.destination.id == qp.id)
                q.qualifyingDestinations.splice(ind, 1)
            },
            fetchpromocodes(q) {
                axios.get('/api/offers/promocodes')
                    .then((response) => {
                        this.promocodes = response.data
                        this.editpromoqualifier = q
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchdestinations(q) {
                axios.get('/api/offers/destinations')
                    .then((response) => {
                        this.destinations = response.data
                        this.editdestinationqualifier = q
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchcategories(q) {
                axios.get('/api/category')
                    .then((response) => {
                        this.categories = response.data

                        this.categories.forEach((c) => {
                            c.children.forEach((cc) => {
                                cc.parent = c
                                cc.children.forEach((ccc) => {
                                    ccc.parent = cc
                                    ccc.children.forEach((cccc) => {
                                        cccc.parent = ccc
                                    })
                                })
                            })
                        })
                        this.editcatqualifier = q
                        this.showcats = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchAttributes() {
                axios.get('/api/attributes')
                    .then((response) => {
                        this.attributes = response.data
                        this.attributes.forEach((att, i) => {
                            att.attributeOptions.forEach((ao) => ao.attribute = { id: att.id, name: att.name, label: att.label })
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            searchproducts() {
                if (this.qprod) {
                    axios.get('/api/product/search?q=' + this.qprod)
                        .then((response) => {
                            this.products = response.data.model
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                else {
                    this.$emit('search-reset')
                    this.products = []
                }
            },
            deleteproductfromqualifier(q, qp) {
                let ind = q.qualifyingProducts.findIndex(x => x.productId == qp.productId)
                q.qualifyingProducts.splice(ind, 1)
            },
            addproducttoqualifier(q, p) {
                if (!q.qualifyingProducts.find(x => x.productId == p.id)) {
                    q.qualifyingProducts.push({
                        productId: p.id,
                        offerQualifierId: q.id,
                        id: 0,
                        product: {
                            id: p.id,
                            name: p.name,
                            //productImages: [{ slug: p.productImages.length ? p.productImages[0] : null }]
                        }
                    })
                }
            },
            addNewAttributeOptionToAttribute() {
                let nao = this.attributeOptionToCreate
                if (nao) {
                    this.attributeOptionToCreate.option.name = this.attributeOptionToCreate.option.label
                    let nn = Object.assign({}, nao)
                    this.attributeToAdd.attributeOptions.push(nn)
                    this.attributeOptionToCreate = null
                    this.attributeOptionToAdd = nn 
                }
            },
            creatNewAttributeOption() {
                if (this.attributeToAdd != null) {
                    let targ = this.attributeToAdd 
                    this.attributeOptionToCreate = {
                        attributeId: targ.id, id: 0, sequence: 100,
                        option: {
                            id: 0, name: '**New Option*', label: '**New Option*', isDefault: false, isTheSize: true,
                            optionTypeId: 1, sequence: 1000, useLabel: true
                        },
                        attribute: {
                            label: targ.label, name: targ.name, id: targ.id
                        }
                    }
                    this.attributeOptionToAdd = null
                    this.$nextTick(() => this.$refs[`${this.editgroup.id}_opt`].select())
                        
                }
            },
            addAttributeOptionToGroup() {
                if (this.attributeOptionToAdd && this.editgroup) {
                    let src = this.attributeOptionToAdd
                    this.editgroup.qualifyingOptions.push({
                        attributeOption: {
                            id: src.id,
                            attributeId: src.attributeId,
                            attribute: Object.assign({}, src.attribute),
                            sequence: 100,
                            optionId: src.option.id,
                            option: Object.assign({}, src.option),
                        },
                        attributeOptionId: src.id,
                        id: 0,
                        offerOptionQualifierId: this.editgroup.id
                    })
                    this.editgroup = null
                }
            },
            deleteAttributeOptionFromGroup(ao, oq) {
                if (ao.id != 0) {
                    let trg = oq.qualifyingOptions.findIndex(x => x.attributeOptionId == ao.id)
                    oq.qualifyingOptions.splice(trg, 1)
                }
            },
        }
    }

</script>
<template>
    <div class="a4-pages">
        <div v-for="ord in orders" :key="ord.id" class="a4-page">
            <div class="columns is-relative mb-5">
                <div class="column is-narrow">
                    <img class="slip-logo" width="120" src="http://www.colourtheirday.com/images/logo.svg" />
                </div>
                <div class="column center-col">
                    <span>ColourTheirDay.com</span>
                </div>
                <div class="column is-narrow center-col has-text-right">
                    <span>
                    Order: {{ord.order_reference.substr(0, 11)}}
                    </span>
                    <span>
                    Date: {{printdate(ord.order_datetime)}}
                    </span>
                </div>
                <div class="colour-menu-bot"></div>
            </div>
            <div class="columns content">
                <div class="column is-one-quarter">
                    <strong>
                        Delivery to
                    </strong>
                    <address-display :address="ord.deliveryAddress"></address-display>
                    <strong>Delivery on:</strong>
                    <delivery-display :delivery="ord.orderedDeliveryChoice"></delivery-display>
                    <p class="mt-4">
                    <strong>Buyer</strong><br />
                        {{ord.appUser.firstName}}&nbsp;{{ord.appUser.lastName}}<br />
                        <small>{{ord.appUser.email}}</small>
                    </p>
                </div>
                <div class="column">
                    <p><strong>Thanks so much for your order</strong></p>
                    <p>I really hope you LOVE it! Please take a photo and post it on 
                    SOCIAL MEDIA - tagging @ColourTheirDay & using hashtag 
                    #ColourTheirDay.
                    </p>
                    <p>Thanks again<br />Andrea</p>

                    <div class="columns mt-5">
                        <strong class="column">
                            {{ord.basket.basketItems.length}} item<template v-if="ord.basket.basketItems.length > 1">s</template>
                        </strong>
                    </div>
                    <div v-for="ln in ord.basket.basketItems" :key="ln.id" class="columns order-line bordered-col">
                        <div class="column is-narrow">
                            <img width="64" height="64" :src="`http://www.colourtheirday.com/images/product/${ln.mainImage.slug}?width=128&format=webp`" />
                        </div>
                        <div class="column is-narrow is-size-5">x {{ln.quantity}}</div>
                        <div class="column">
                            <strong v-html="ln.sku.product.name"></strong>
                            <p>
                                <div v-for="(ao, i) in ln.sku.skuOptions.filter(x=>x.attributeOption.attribute.attributeType == 1)" :key="i">
                                    {{ao.attributeOption.attribute.label}}: {{ao.attributeOption.option.label}}
                                </div>
                            </p>
                            <p v-if="ln.basketItemPersonalisations.length > 0">
                                <div v-for="(p, i) in ln.basketItemPersonalisations" :key="i">
                                    {{p.name}}: {{p.value}}
                                </div>
                            </p>
                        </div>
                        <div class="column is-narrow">£ {{ln.lineTotalIncVat.toFixed(2)}}</div>
                    </div>
                    <div class="totals-slip">
                        <div class="columns total-slip">
                            <div class="column has-text-right">
                                Item total<br />
                                Delivery<br />
                                <template v-if="ord.savingTotal > 0">Savings<br /></template>
                            </div>
                            <div class="column  is-one-quarter has-text-right">
                                &pound;{{ord.itemTotal.toFixed(2)}}<br />
                                &pound;{{ord.deliveryTotal.toFixed(2)}}<br />
                                <template v-if="ord.savingTotal > 0">&pound;{{ord.savingTotal.toFixed(2)}}</template>
                            </div>
                        </div>
                        <div class="columns total-slip">
                            <div class="column has-text-right">
                                <strong>Order total</strong>
                            </div>
                            <div class="column is-one-quarter has-text-right">
                                <strong>&pound;{{ord.orderTotal.toFixed(2)}}</strong>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>

        </div>
    </div>
</template>

<style>
    @media screen {
        .a4-page { padding: 3rem; }
    }
    @page { size: A4 portrait }
    .a4-page { page-break-before: always; position: relative; }
    .colour-menu-bot {
        position: absolute;
        top: 5.25rem;
        background-color: #f90;
        height: 0.6rem;
        left: 0;
        right: 0;
        z-index:1;
    }
    .order-line, .totals-slip {
        page-break-inside: avoid;
    }
    .center-col {
        display: flex;
        align-content: space-around;
        flex-direction: column;
        justify-content: center;
    }
    .bordered-col {
        border-top: 2px solid #ebebeb;
    }
    .slip-logo { position: relative; z-index: 2; top: 1.5rem; margin-left: 0.75rem; }
    .colour-menu-bot {
        background: linear-gradient(270deg, #fd81d5, #cc00ff, #3399ff, #6deb35, #ffea00, #ffbc00, #ff9900, #ff6600, #ff0000, #fd81d5, #cc00ff, #3399ff, #6deb35, #ffea00, #ffbc00, #ff9900, #ff6600, #ff0000);
        background-size: 222% 222%;
        animation: rainbow 80s ease infinite;

        @keyframes rainbow {
            0% {
                background-position: 0% 51%
            }

            50% {
                background-position: 100% 50%
            }

            100% {
                background-position: 0% 51%
            }
        }
    }

</style>

<script>
    import axios from 'axios'
    import AddressDisplay from './AddressDisplay.vue'
    import DeliveryDisplay from './DeliveryChoiceDisplay.vue'

    export default {
        props: {
            orderIds: Array
        },
        components: {
            AddressDisplay, DeliveryDisplay
        },
        name: 'PackingSlipDisplay',
        data() {
            return {
                orders: []
            }
        },
        mounted() {
            this.fetchorders()
        },
        watch: {
            orderIds() {
                this.fetchorders()
            }
        },
        methods: {
            fetchorders() {
                axios.post(`/api/order/print/`, this.orderIds)
                    .then((response) => {
                        this.orders = response.data;
                        this.orders.forEach((v) => {
                            v.itemTotal = v.basket.basketItems.reduce((p, c) => p+c.lineTotalIncVat, 0)
                            v.deliveryTotal = v.orderedDeliveryChoice.deliveryPriceIncVat
                            v.orderTotal = v.itemTotal + v.deliveryTotal
                            v.savingTotal = v.basket.basketItems.reduce(
                                (p, c) => {
                                    return p + (c.basePriceIncVat * c.quantity) - (c.priceIncVat * c.quantity)
                                }, 0
                            )
                            v.savingTotal += v.orderedDeliveryChoice.deliverySavingAmountIncVat
                            v.basket.basketItems.forEach((b, i) => {
                                let si = b.sku.skuProductImages.length ? b.sku.skuProductImages.sort((a, b) => a.sequence - b.sequence)[0] : null
                                let pi = b.sku.product.productImages.length ? b.sku.product.productImages.sort((a, b) => a.sequence - b.sequence)[0] : null

                                b.mainImage = (si ? si.productImage : null) || pi
                            })
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            printdate(json) {
                let d = new Date(json)
                return d.toLocaleDateString('en-gb', { year: 'numeric', month: 'short', day: 'numeric' })
            }
        }
    }

</script>


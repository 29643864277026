<template>
    <nav-menu @search="dosearch" v-model="q"></nav-menu>
    <router-view :searchterm = "q" />
</template>

<script>
    import NavMenu from './components/NavMenu.vue'
    

    export default {
        name: 'App',
        data() {
            return {
                q: '',
            }
        },
        methods: {
            dosearch(st) {
                this.q = st
                console.log(st)
            }
        },
        components: {
            NavMenu
        }
    }
</script>

<style>
#app {
    padding-top: 3.5rem;
}
.navbar.is-fixed-top { z-index: 300; }
.modal { z-index: 500; }
</style>

<template>
    <div>
        <div class="container login-form">
            <div class="content">
                <h1>Please login to continue</h1>
                <p v-if="message">
                    {{message}}
                </p>
                <div v-if="(error.Password && error.Password.length) || (error.Username && error.Username.length)">
                    <p v-for="err in error.Username">
                        {{err}}
                    </p>
                    <p v-for="err in error.Password">
                        {{err}}
                    </p>
                </div>
            </div>
            <div class="field">
                <label class="label">Email</label>
                <input v-model="user" class="input" type="text" />
            </div>
            <div class="field">
                <label class="label">Password</label>
                <input v-model="pass" class="input" type="password" />
            </div>
            <div class="field">
                <button class="button is-primary is-medium" @click="dologin">Login</button>
            </div>
            <p><button @click="logout">Log Out</button></p>
        </div>
    </div>
</template>

<style>
    .login-form { margin-top: 4rem; }
</style>
<script>
    export default {
        name: "Login",
        components: {

        },
        data() {
            return {
                user: null,
                pass: null,
                error: {},
                message: null
            }
        },
        methods: {
            logout() {
                localStorage.removeItem('user');
            },
            dologin() {
                this.message = null;
                
                let requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Username: this.user, Password: this.pass })
                }
                let that = this;
                fetch(`/api/auth/login`, requestOptions)
                .then((response) => {
                    return response.text().then(text => {
                        const data = text && JSON.parse(text);
                        if (!response.ok) {
                            if (response.status === 401) {
                                // auto logout if 401 response returned from api
                                localStorage.removeItem('user');
                                //location.reload(true);
                            }

                            if (data.errors)
                                that.error = data.errors;
                            else
                                that.message = data.title;

                            return Promise.reject((data && data.message) || response.statusText);
                        }

                        return data;
                    });
                })
                .then(user => {
                    // login successful if there's a jwt token in the response
                    if (user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(user));
                        this.$router.replace(this.$route.query.redirect || '');
                    }
                    return user;
                });
            }
        }
    }
</script>
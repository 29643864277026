<template>
    <div class="container clue">
        <div class="columns">
            <div class="column">
                Possible guesses {{numcombos.length}}
                
                <table class="is-narrow is-bordered is-striped table">
                    <tbody>
                        <tr class="last">
                            <td>Who Plays?</td>
                            <td class="t-center" v-for="p in players" :title="p.name" :style="`background-color:${p.color};`">
                                <label>
                                <input type="checkbox" :value="true" v-model="p.active" />
                                </label>
                            </td>
                        </tr>
                        <tr class="last">
                            <td>I am...</td>
                            <td class="t-center" v-for="p in players" :title="p.name" :class="{'is-self': self==p}">
                                <label  v-if="p.active">
                                <input type="radio" :value="p" v-model="self" />
                                </label>
                            </td>
                        </tr>
                        <tr class="last">
                            <td>Cards each?</td>
                            <td v-for="p in players" >
                                <input @input="checknums" @keyup.up="()=> {p.numcards++;checknums()}"  @keyup.down="()=> {p.numcards--;checknums()}" v-if="p.active==true" v-model="p.numcards" class="numcards input" :class="{'is-danger': !numcardsok}" />
                            </td>
                        </tr>

                        <tr v-for="card in matrix.cards" :class="{ last: card.class, 'isnotit': card.players.find(x=>x.has == 1), 'isright': card.players.filter(x=>x.has == -1 && x.player.active).length == totplayers}">
                            <td :class="{isselected: selectedcards.includes(card)}">
                                <label><input type="checkbox" :value="card" v-model="selectedcards" />
                                 &nbsp; {{card.name}}
                                </label>
                            </td>
                            <td v-for="p in card.players" :class="{ might: p.might , editing: current && current.player == p, off: p.player.active==false }" class="known-cell" 
                                @click="current.player = p; current.card = card">
                                <template v-if="p.player.active">
                                    <span v-if="p.has == 0" class="unknown">
                                        
                                    </span>
                                    <span v-if="p.has == -1" class="hasnot">
                                        &#10060;
                                    </span>
                                    <span v-if="p.has == 1" class="has">
                                        &#9989;
                                    </span>
                                    <span class="tag mr-1" v-for="g in matrix.guesses.filter(g=>g.cards.includes(card) && g.responder == p.player)">
                                        {{g.index}}
                                    </span>
                                </template>
                                <div class="clue-set-menu" v-if="current.player == p && current.card == card">
                                    <div class="setwrap">
                                        <button @click.stop="setknown(1, p, card.players)" class="button is-large">&#9989;</button>
                                        <button @click.stop="setknown(-1, p, card.players)" class="button is-large">&#10060;</button>
                                        <button @click.stop="setknown(0, p, card.players)" class="button is-large">&#129335;</button>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column">
                <table class="is-narrow is-bordered  table">
                    <thead>
                        <tr>
                            <td>Player</td>
                            <td>Cards held</td>
                            <td>I showed</td>
                        </tr>
                        <tr v-for="p in players.filter(p=>p.active)">
                            <td  :style="`background-color:${p.color};`">
                                {{p.name}}
                            </td>
                            <td>
                                <span v-if="matrix && matrix.players[p.index]" class="tags">
                                    <span v-for="cd in matrix.players[p.index].cards.filter(c=>c.player.has==1)" class="tag">{{cd.card.name}}</span>
                                </span>
                            </td>
                            <td>
                                <span v-if="matrix" class="tags">
                                    <span v-for="s in matrix.shown.filter(c=>c.viewer.name==p.name)" class="tag">{{s.card.name}}</span>
                                </span>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table v-if="matrix.guesses.length" class="is-narrow is-bordered  table">
                    <thead>
                        <tr>
                            <td class="is-size-7">#</td>
                            <td class="is-size-7">Accuser</td>
                            <td class="is-size-7">cards</td>
                            <td class="is-size-7">Who had one</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="g in matrix.guesses">
                            <td>{{g.index}}</td>
                            <td :style="{'padding-left': '1.5rem' , 'box-shadow': `inset 16px 0px 0px 0px ${g.accuser.color}`}">{{g.accuser.name}}</td>
                            <td>
                                <div class="tags">
                                <template  v-for="c in g.cards">
                                    <span v-if="c.players.find(p=>p.name == g.responder.name && p.has == -1)" :style="{'border': c.color ? `1px solid ${c.color}` : '1px solid #ebebeb'}" class="tag is-light guess-tag notgot">&#10060;&nbsp;{{c.name}}</span>
                                    <span v-else-if="c.players.find(p=>p.name == g.responder.name && p.has == 1)" :style="{'border': c.color ? `1px solid ${c.color}` : '1px solid #ebebeb'}" class="tag is-light guess-tag hasgot">&#9989;&nbsp;{{c.name}}</span>
                                    <span v-else class="tag is-light guess-tag">{{c.name}}</span>
                                </template>
                                </div>
                            </td>
                            <td :style="{'background-color': g.responder.color}">{{g.responder.name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
            <div class="clue-menu" v-if="selectedcards.length == 3 && selectedcards.find(x=>x.type=='R') && selectedcards.find(x=>x.type=='W') && selectedcards.find(x=>x.type=='P')">
                <div class="container p-3">
                    <div class="columns">
                        <div class="column">
                            Who Said This?
                            <div class="buttons">
                                <button :style="{'background-color': accuser && accuser != p ? '#fff' : p.color}" 
                                        v-for="p in players.filter(x=>x.active)" @click="accuser = p" class="button">{{p.name}}</button>
                            </div>
                        </div>
                        <div v-if="!isbeingdumb.smart" class="column is-narrow">
                            <span class="notification is-warning">
                            {{isbeingdumb.msg}}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="accuser != null" class="container p-3">
                    Who didn't have'?
                    <div class="buttons">
                        <button :disabled="accuser && accuser==p" :style="{'background-color': accuser && accuser != p && (!responder || (responder && responder == p)) ? p.color : '#f7f7f7'}" 
                                v-for="p in players.filter(x=>x.active)" @click="setnothas(p, false)" class="button">{{p.name}}</button>
                        
                        <button @click="setwinner" class="button">No one!</button>
                    </div>
                </div>
                <div v-if="accuser != null" class="container p-3">
                    Who responded?
                    <div class="buttons">
                        <button :disabled="accuser && accuser==p || didnthave.find(d => d.name == p.name)" :style="{'background-color': accuser && accuser != p && (!responder || (responder && responder == p)) ? p.color : '#f7f7f7'}" 
                                v-for="p in players.filter(x=>x.active)" @click="setguess(p)" class="button">{{p.name}}</button>
                        
                    </div>
                </div>
                <div v-if="accuser == self && responder != null && responder != self" class="container p-3">
                    They showed?
                    <div class="buttons">
                        <button class="button" 
                                @click="setknown(1, c.players.find(x=> x.name == responder.name), c.players, true)" 
                                v-for="c in selectedcards">
                            <span v-if="c.players.find(p=> p.name == responder.name && p.has == 1)">&#9989;</span>
                            <span v-if="c.players.find(p=> p.name == responder.name && p.has == -1)">&#10060;</span>                        {{c.name}}</button>
                    </div>
                </div>
                <div v-if="responder == self && self != null" class="container p-3">
                    YOU showed?
                    <div class="buttons">
                        <button class="button" 
                                @click="setshown(c, accuser)" 
                                v-for="c in selectedcards">
                            <span v-if="c.players.find(p=> p.name == responder.name && p.has == 1)">&#9989;</span>
                            <span v-if="c.players.find(p=> p.name == responder.name && p.has == -1)">&#10060;</span>                        {{c.name}}</button>
                    </div>
                </div>                
                <button @click="reset" class="delete is-large clue-delete"></button>
            </div>

    </div>
</template>

<style>
    td { white-space: nowrap; }
    td label { display: block; cursor:pointer; }
    td.is-self { background-color: #999; }
    .guess-tag {  }
    .setwrap { border: 3px solid #666; }
    .clue-delete { position: absolute; right: 2rem; top: 2rem; }
    .isright .tag, .isnotit .tag  { opacity: 0.25; }
    .isnotit, .isnotit td.might { background-color: #797979 !important; color: #fff; }
    .isright, .isright td.might { background-color: #1c1c1c !important; color: #FFF !important; }
    .clue { margin-top: 10rem; }
    .clue-set-menu { 
        position: absolute; left:0; top: 100%; z-index: 100000; 
        background-color: #666; 
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        overflow:hidden;
    }
    .clue-menu { position: fixed; left:0; top: 0; right: 0; 
                 box-shadow: 0 0 2rem 2rem #0000005e;
                 z-index: 100000; background-color: #efefef;
                 
    }
    .clue-set-menu button { width: 5rem; margin: 0.25rem 0.25rem; }
    table.clue.is-striped:nth-child(even) {
    background-color: #fff;
    }
    .isselected { background-color: #b6ff00; }
    input.numcards { width: 2rem; }
    tr.last { border-bottom: 2px solid #666; }
    .unknown { color: #ccc; }
    td.t-center { text-align: center !important; }
    td.might { background-color: #e0a758; }
    td.known-cell { cursor: pointer; width: 3rem; position:relative; }
    td.known-cell.off { background-color: #dbdbdb; border-color: transparent !important; }
    td.editing { background-color: #666; }
</style>

<script>
    export default {
        name: "Cluedo",
        components: {

        },
        data() {
            return {
                selectedcards: [],
                accuser: null,
                self: null,
                responder: null,
                didnthave: [],
                current: { player: null, card: null },
                numcardsok: true,
                players: [
                    { name:'Green', active: true, color:'#008000', numcards: 3 },
                    { name:'Mustard', active:true, color:'#ebc629', numcards: 3 },
                    { name:'Orchid', active:true, color:'#ffa0f5', numcards: 3 },
                    { name:'Peacock', active:true, color:'#618eff', numcards: 3 },
                    { name:'Plum', active:true, color:'#8549ff', numcards: 3 },
                    { name:'Scarlet', active:true, color:'#e5281a', numcards: 3 }
                ],
                weapons: ['Rope', 'Candlestick', 'Dagger', 'Wrench', 'Lead Pipe', 'Revolver'],
                rooms: [
                    'Kitchen',
                    'Hall',
                    'Ballroom',
                    'Conservatory',
                    'Dining Room',
                    //'Cellar',
                    'Billiard Room',
                    'Library',
                    'Lounge',
                    'Study'
                ],
                matrix: {
                    cards: [],
                    players: [],
                    guesses: [],
                    shown: []
                }
            }
        },
        mounted() {
            this.players.forEach((v, i) => {
                v.index = i
                this.matrix.players.push({ cards: [], player:v })
            })
            this.players.forEach((v, i) => {
                let c = {
                    name: v.name, players: [], type: 'P',
                    color: v.color, class: '', selected: false,
                    index: this.matrix.cards.length
                }
                this.players.forEach((p, j) => {
                    let pl = { name:p.name, has: 0, might: 0, player: p }
                    c.players.push(pl)
                    this.matrix.players[j].cards.push({ player: pl, card: c })
                })
                this.matrix.cards.push(c)
            })
            this.matrix.cards[this.matrix.cards.length-1].class = 'last'
            this.weapons.forEach((v, i) => {
                let c = {
                    name: v, players: [], type: 'W',
                    class: '', selected: false,
                    index: this.matrix.cards.length
                }
                this.players.forEach((p, j) => {
                    let pl = { name:p.name, has: 0, might: 0, player: p }
                    c.players.push(pl)
                    this.matrix.players[j].cards.push({ player: pl, card: c })
                })
                this.matrix.cards.push(c)
            })
            this.matrix.cards[this.matrix.cards.length-1].class = 'last'
            this.rooms.forEach((v, i) => {
                let c = {
                    name: v, players: [], type: 'R',
                    class: '', selected: false,
                    index: this.matrix.cards.length
                }
                this.players.forEach((p, j) => {
                    let pl = { name:p.name, has: 0, might: 0, player: p }
                    c.players.push(pl)
                    this.matrix.players[j].cards.push({ player: pl, card: c })
                })
                this.matrix.cards.push(c)
            })

        },
        computed: {
            totplayers() {
                return this.players.filter(x=>x.active == true).length
            },
            numcardsset() {
                let num = 0
                //this.players.filter(x => x.active == true).forEach(v => num += v.numcards)
                //this.numcardsok = (num == this.matrix.cards.length-3)
                return num
            },
            numcombos() {
                let ucards = this.matrix.cards.filter(x => !x.players.find(y => y.has == 1))
                let uplayers = ucards.filter(x=>x.type=='P')
                let uweapons = ucards.filter(x=>x.type=='W')
                let urooms   = ucards.filter(x=>x.type=='R')
                return this.cartesian(uplayers, uweapons, urooms)
            },
            isbeingdumb() {
                let r = { smart: true, msg: null }
                if (this.selectedcards.length == 3) {
                    if (this.accuser == this.self) {
                        let res = this.selectedcards.some(c => c.players.some(p => p.has == 1))
                        r.smart = !res
                        if(!r.smart)
                            r.msg = 'You know some already!'
                        else
                            r.msg = 'Ok then'
                    }
                }
                return r
            }
        },
        watch: {
            totplayers(nv) {
                let tot = this.matrix.cards.length - 3
                let min = Math.floor(tot/nv)
                this.players.filter(x => x.active == true).forEach(v => v.numcards = min)

                this.checknums()
            }
        },
        methods: {
            setnothas(plr, conf) {
                if (this.selectedcards && this.selectedcards.length == 3) {
                    if (conf === true) {
                        this.selectedcards.forEach(c =>
                            c.players.filter(p =>
                                p.name == plr.name
                            ).forEach(p =>
                                p.has = -1
                            ))
                        this.didnthave.push(plr)
                    } else {
                        if (confirm(plr.name + ' NOT got them?'))
                            this.setnothas(plr, true)
                    }
                }
            },
            checkconf(plr) {
                this.showconf == true
                this.who = plr
            },
            setwinner() {
                if (this.selectedcards && this.selectedcards.length == 3)
                    this.selectedcards.forEach(c =>
                        c.players.filter(p =>
                            p.has != -1
                        ).forEach(p =>
                            p.has = -1
                        ))
                this.reset()
            },
            setshown(card, viewer) {
                let has = this.matrix.shown.find(x=>x.card.name == card.name)
                if (has)
                    has.count++
                else
                    this.matrix.shown.push({ viewer, card, count: 0 })

                this.reset()
            },
            setguess(p) {
                let acc = this.accuser
                this.responder = p
                let gu = {
                    accuser: acc,
                    responder: p,
                    cards: this.selectedcards.slice(0).sort((a, b) => {
                        return a.index - b.index
                    }),
                    index: this.matrix.guesses.length + 1//,
                    //has: []
                }
                this.matrix.guesses.push(gu)

                if (this.accuser != this.self && this.responder != this.self) 
                    this.reset()

                this.checkguess()
                this.checkfound()
            },
            checkguess() {
                this.matrix.guesses.forEach((gu) => {
                    let chk = []
                    gu.cards.forEach((c, i) => {
                        let res = c.players.find(p => p.name == gu.responder.name)
                        chk.push({ index: i, player: res, has: res.has, card: c })
                    })
                    let hasnotgot = chk.filter(k => k.has == -1)

                    if (hasnotgot.length == 2) {
                        let found = chk.find(c => c.has != -1)
                        if (found) {
                            found.player.has = 1
                            found.player.might = 0
                        }
                    } else {
                        if (hasnotgot.length == 1) {
                            let mights = chk.filter(c => c.has == 0)
                            mights.forEach(m => m.player.might = 1)
                        }
                    }
                })
            },
            checkfound() {
                let foundcards = this.matrix.cards.filter(x => x.players.filter(p => p.player.active).some(y => y.has == 1))
                let notfound = this.matrix.cards.filter(x => x.players.filter(p => p.player.active).every(y => y.has != 1))
                let foundp = foundcards.filter(x => x.type == 'P')
                let foundw = foundcards.filter(x => x.type == 'W')
                let foundr = foundcards.filter(x => x.type == 'R')
                if (foundp.length == 5)
                    notfound.filter(n=>n.type=='P').forEach(p => p.players.filter(p=>p.has==0).forEach(p => p.has = -1))
                if (foundw.length == 5)
                    notfound.filter(n=>n.type=='W').forEach(p => p.players.filter(p=>p.has==0).forEach(p => p.has = -1))
                if (foundr.length == 8)
                    notfound.filter(n=>n.type=='R').forEach(p => p.players.filter(p=>p.has==0).forEach(p => p.has = -1))
            },
            checkplayers() {
                this.matrix.players.forEach(m => {
                    let known = m.cards.filter(x => x.player.has == 1)
                    if (known.length == m.player.numcards) {
                        m.cards.filter(x => x.player.has != 1).forEach(c => {
                            c.player.has = -1
                            c.player.might = 0
                        })
                        this.checkguess()
                        this.checkfound()
                    }
                })
            },
            checknums() {
                let tot = this.matrix.cards.length - 3
                let num = 0
                this.players.filter(x => x.active == true).forEach(v => num += (+v.numcards))

                this.numcardsok = num == tot
            },
            setknown(v, p, players, reset) {
                if (v == 1)
                    players.filter(x => x.player.active == true).forEach(pl => {
                        pl.has = -1
                        pl.might = 0
                    })

                let prenots = players.filter(x => x.has == -1 && x.player.active == true)
                if (prenots.length == (this.totplayers - 1) && p.has == 1) {
                    this.current.player = null
                    this.current.card = null

                    return
                } 

                p.has = v

                let nots = players.filter(x => x.has == -1 && x.player.active == true)

                if (v == -1) { // 
                    //if (nots.length == this.totplayers - 1)
                    //    players.filter(x => x.has != -1 && x.player.active == true).forEach(v => v.has = 1)
                } 
                if (v == 0) {
                    let hasone = players.find(x => x.has == 1 && x.player.active == true)
                    if(hasone && nots.length < this.totplayers - 1)
                        players.filter(x => x.has == 1 && x.player.active == true).forEach(v => {
                            v.has = 0
                        })
                } 

                
                

                this.current.player = null
                this.current.card = null

                if (reset) {
                    this.reset()
                }

                this.checkguess()
                this.checkplayers()
                this.checkfound()
            },
            reset() {
                this.selectedcards = []
                this.accuser = null
                this.responder = null
                this.didnthave = []
            },
            cartesian(...args) {
                var r = [], max = args.length-1;
                function helper(arr, i) {
                    for (var j=0, l=args[i].length; j<l; j++) {
                        var a = arr.slice(0); // clone arr
                        a.push(args[i][j]);
                        if (i==max)
                            r.push(a);
                        else
                            helper(a, i+1);
                    }
                }
                helper([], 0);
                console.log(r)
                return r;
            }
        }
    }
    /*  

    * Generate all combinations of an array.
    * @param {Array} sourceArray - Array of input elements.
    * @param {number} comboLength - Desired length of combinations.
    * @return {Array} Array of combination arrays.
 
    function generateCombinations(sourceArray, comboLength) {
        const sourceLength = sourceArray.length;
        if (comboLength > sourceLength) return [];

        const combos = []; // Stores valid combinations as they are generated.

        // Accepts a partial combination, an index into sourceArray, 
        // and the number of elements required to be added to create a full-length combination.
        // Called recursively to build combinations, adding subsequent elements at each call depth.
        const makeNextCombos = (workingCombo, currentIndex, remainingCount) => {
        const oneAwayFromComboLength = remainingCount == 1;

        // For each element that remaines to be added to the working combination.
        for (let sourceIndex = currentIndex; sourceIndex < sourceLength; sourceIndex++) {
            // Get next (possibly partial) combination.
            const next = [ ...workingCombo, sourceArray[sourceIndex] ];

            if (oneAwayFromComboLength) {
            // Combo of right length found, save it.
            combos.push(next);
            }
            else {
            // Otherwise go deeper to add more elements to the current partial combination.
            makeNextCombos(next, sourceIndex + 1, remainingCount - 1);
            }
        }
    }

    makeNextCombos([], 0, comboLength);
    return combos;
    }
     
     */
</script>


<template>
    <div class="container product-edit">
        <div class="columns" v-if="user">
            <div class="column">
                <div class="field">
                    <div class="columns">
                        <div class="column">
                            <label class="label">First Name</label>
                            <input v-model="user.firstName" class="input" />
                        </div>
                        <div class="column">
                            <label class="label">Last Name</label>
                            <input v-model="user.lastName" class="input" />
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div class="columns">
                        <div class="column is-half">
                            <label class="label">Email</label>
                            <input v-model="user.email" class="input" />
                        </div>
                        <div class="column ">
                            <label class="label">Roles</label>
                            <div class="tags" v-if="user.appRoles.length">
                                <span class="tag is-large" v-for="role in user.appRoles">{{role.name}} <span @click="removerole(role.name)" class="delete"></span></span>
                            </div>
                            <div v-else>None</div>
                        </div>
                        <div class="column">
                            <label class="label">Add Role to User</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <div class="select">
                                        <select v-model="addrole">
                                            <option value="">Select a role to add</option>
                                            <option v-for="role in roles" :value="role.name">{{role.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <button @click="sendaddrole" class="button is-info">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="field">
                    <div class="columns">
                        <div class="column is-narrow">
                            <label class="label">Is Guest</label>
                            <bool-tick v-model="user.isGuest"></bool-tick>
                        </div>
                        <div class="column is-narrow">
                            <label class="label">Pay on Account</label>
                            <bool-tick v-model="user.canPayOnAccount"></bool-tick>
                        </div>
                        <div class="column is-narrow">
                            <label class="label">Account Code</label>
                            <input v-model="user.accountCode" class="input" />
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Phone</label>
                            <input v-model="user.phoneNumber" class="input" />
                        </div>
                        <div class="column">
                            <label class="label">Addresses</label>
                            <div v-for="ad in user.addresses">
                                {{easyaddress(ad)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column is-half">
                                    <label class="label">Action (value)</label>
                                </div>
                                <div class="column">
                                    <label class="label">Date Awarded</label>
                                </div>
                            </div>
                            <div v-for="act in user.appUserProfileActions" class="columns">
                                <div class="column pb-0 is-half">
                                    {{act.trigger.name}} => {{act.profileAction.name}} ({{act.value}})
                                </div>
                                <div class="column pb-0">
                                    {{Date(act.dateAwarded).toLocaleString()}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <footer class="has-background-light">
                    <div class="columns">
                        <div class="buttons">
                            <button @click="$router.push('/Users')" class="button">Cancel</button>
                            <button @click="validate" class="button is-primary">Save</button>
                        </div>
                    </div>
                </footer>
            </div>
                </div>
            </div>
    
</template>
<style>

</style>
<script>
    import axios from 'axios'
    import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    //import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            searchterm: String
        },
        emits: ['cancelSave', 'doSave'],
        name: "UserEdit",
        components: {
            BoolTick
        },
        computed: {

        },
        data() {
            return {
                user: null,
                roles: [],
                addrole: ''
            }
        },
        mounted() {
            this.getuser(this.$route.params.id)
            this.fetchroles()
        },
        methods: {
            sendaddrole() {
                axios.post('/api/users/addrole', { id: this.user.id, role: this.addrole })
                    .then((response) => {
                        if (response) {
                            //this.$emit('doSave', response.data)
                            //this.user = response.data
                            console.log(`added: ${this.addrole}`)
                            this.getuser(this.$route.params.id)
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            removerole(role) {
                axios.post('/api/users/removerole', { id: this.user.id, role: role })
                    .then((response) => {
                        if (response) {
                            //this.$emit('doSave', response.data)
                            //this.user = response.data
                            console.log(`removed: ${role}`)
                            this.getuser(this.$route.params.id)
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            easyaddress(ad) {
                let r = [`#${ad.id}`]
                if (ad.address1)
                    r.push(ad.address1)
                if (ad.address2)
                    r.push(ad.address2)
                if (ad.address3)
                    r.push(ad.address3)
                if (ad.county)
                    r.push(ad.county)
                if (ad.town)
                    r.push(ad.town)
                if (ad.postcode)
                    r.push(ad.postcode)

                return r.join(', ')

            },
            validate(event) {
                let req = document.querySelectorAll('input.input[required]')
                let valid = true
                req.forEach((el) => {
                    el.classList.remove('is-danger')
                    if (!el.value) {
                        el.classList.add('is-danger')
                        valid = false
                    }
                    else
                        el.classList.remove('is-danger')
                })
                if(!valid)
                    document.querySelectorAll('input.is-danger.input[required]')[0].focus()
                else 
                    this.saveuser()
                

            },
            fetchroles() {
                axios.get('/api/users/roles')
                    .then((response) => {
                        this.roles = response.data.roles;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getuser(id) {
                axios.get(`/api/users/${id}`)
                    .then((response) => {
                        this.$emit('doSave', response.data)
                        this.user = response.data
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveuser() {
                axios.post('/api/users/update', this.user)
                    .then((response) => {
                        if (response && response.data) {
                            this.$emit('doSave', response.data)
                            this.user = response.data
                            console.log('saved')
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    }
</script>
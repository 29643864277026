import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
//import Counter from "@/components/Counter.vue";
import Login from "@/components/Login.vue";
import Cluedo from "@/components/Cluedo.vue";
import ClearOfferCache from "../components/ClearOfferCache.vue";
import ProductEdit from "../components/ProductEdit.vue";
import ProductList from "../components/ProductList.vue";
import CategoryAdmin from "../components/CategoryAdmin.vue";
import UsersList from "../components/UsersList.vue";
import UserEdit from "../components/UserEdit.vue";
import OrdersAdmin from "../components/Orders.vue";
import OffersAdmin from "../components/OfferAdmin.vue";
import PromoCodeAdmin from "../components/PromoCodeAdmin.vue";
import PersonalisationsAdmin from "../components/PersonalisationsAdmin.vue";
//import OfferTemplater from "@/components/OfferTemplater.vue"

const routes = [
    {
        path: "/",
        component: Home,
        meta: { requiresAuth: true }
    }
    ,
    {
        path: "/Products",
        component: ProductList,
        meta: { requiresAuth: true }
    },
    {
        path: '/Products/:id',
        component: ProductEdit,
        meta: { requiresAuth: true }
    },
    {
        path: "/Categories",
        component: CategoryAdmin,
        meta: { requiresAuth: true }
    },
    {
        path: "/Orders",
        component: OrdersAdmin,
        meta: { requiresAuth: true }
    },
    {
        path: "/Users",
        component: UsersList,
        meta: { requiresAuth: true }
    },
    {
        path: "/Users/:id",
        component: UserEdit,
        //component: UsersList,
        meta: { requiresAuth: true }
    },
    {
        path: "/Offers",
        component: OffersAdmin,
        meta: { requiresAuth: true },
    },
    {
        path: "/ClearOfferCache",
        component: ClearOfferCache,
        meta: { requiresAuth: true },
    },
    {
        path: "/PromoCodes",
        name: "PromoCodes",
        component: PromoCodeAdmin,
        meta: { requiresAuth: true },
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: '/Personalisations',
        name: "Personalisations",
        component: PersonalisationsAdmin,
        meta: { requiresAuth: true }
    },
    {
        path: '/Cluedo',
        name: '/Cluedo',
        component: Cluedo,
    }
    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const loggedIn = localStorage.getItem('user');

    if (to.meta.requiresAuth && !loggedIn) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        next({
            path: '/login',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        });
    } else
        next();
})

export default router;
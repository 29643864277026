<template>
    <div class="container product-edit">
        <p>{{searchterm}}</p>
        <!--
        <div class="navbar is-info mb-6">
            <div class="navbar-brand">
                <search @search-reset="fetchproducts" @search-products="(p) => { products = p; count=p.length; insearch=true; selectedproduct=null  }"></search>
            </div>
            <div class="navbar-menu">
                <div class="navbar-start">
                    <div class="navbar-item publish-filter">
                        <label class="radio">All <input type="radio" :value="null" name="filterpublished" v-model="filter.published" /></label>
                        <label class="radio">Published <input type="radio" :value="true" name="filterpublished" v-model="filter.published" /></label>
                        <label class="radio">Unpublished <input type="radio" :value="false" name="filterpublished" v-model="filter.published" /></label>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="field is-grouped">
                            <p class="control">
                                <button class="button" @click="getnewproduct">Add New</button>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    <router-view :types="types" :templates="templates" :imgbase="imgbase" :uploadurl="uploadurl"></router-view>

    -->

        <div class="has-text-right add-new-here ">
            <button class="button is-info" @click="$router.push(`/Products/0`)">Add New</button>
        </div>
        <div class="columns is-striped" v-for="(prod, i) in filteredproducts" :key="i">
            <div v-if="prod.productImages.length>0" class="column is-narrow">
                <img loading="lazy" width="64" height="64" :src="`${imgbase}/${prod.productImages[0].slug}?format=webp&width=128`" />
            </div>
            <div class="column is-narrow">
                #{{prod.id}}
            </div>
            <div class="column">
                {{prod.name}}
            </div>
            <div v-if="prod.productType" class="column">
                {{prod.productType.name}}
            </div>
            <div v-else class="column">
                unknown type
            </div>
            <div class="column is-narrow">
                <yes-no-blob :value="prod.published"></yes-no-blob>
            </div>
            <div class="column is-narrow"><button @click="$router.push(`/Products/${prod.id}`)" class="button">Edit</button></div>
        </div>
        <footer class="has-background-light">
            <button class="button" @click="currentpage--" :class="{'is-disabled':currentpage==1}">Prev Page</button>
            <div class="select">
                <select v-model="currentpage">
                    <option v-for="page in Math.ceil(count/pagesize)" :value="page" :key="page">{{page}}</option>
                </select>
            </div>
            <button class="button" @click="currentpage++" :class="{'is-disabled':currentpage==Math.floor(count/pagesize)}">Next page</button>
        </footer>
    </div>
</template>
<style>
    footer {
        padding: 1.5rem;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .add-new-here { margin-bottom: 2rem; }
    .is-striped:nth-child(even) {
        background-color: #f7f7f7;
    }

</style>
<script>
    /* eslint-disable */
    import axios from 'axios'
    import YesNoBlob from './YesNoBlob.vue'
    export default {
        name: "ProductList",
        components: {
            
            YesNoBlob
        },
        props: { q: String , searchterm: String },
        data() {
            return {
                products: [],
                count: 0,
                currentpage: 1,
                filter: { published: null },
                pagesize: 30,
                insearch: false,
                imgbase: `${this.$host}/images/product/`,
                uploadurl: this.$host,
                types: [],
                templates: [],
            }
        },
        methods: {
            dosearch() {
                if (this.searchterm.length > 2) {
                    axios.get('/api/product/search?q=' + this.searchterm)
                        .then((response) => {
                            this.products = response.data.model
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                else {
                    //this.$emit('search-reset')
                }
            },
            fetchproducts() {

                axios.get('/api/product?t=' + this.pagesize + '&p=' + this.currentpage)
                    .then((response) => {
                        if (response && response.data && response.data.model) {
                            this.products = response.data.model;
                            this.count = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchproducttypes() {
                axios.get('/api/product/types')
                    .then((response) => {
                        this.types = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchtemplates() {
                axios.get('/api/product/templates')
                    .then((response) => {
                        this.templates = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
        watch: {
            currentpage() {
                this.fetchproducts()
            },
            searchterm(nv) {
                if (nv.length == 0)
                    this.fetchproducts()
                else
                    this.dosearch()
            }
        },
        mounted() {
            if (this.searchterm.length)
                this.dosearch()
            else
                this.fetchproducts()

            this.fetchproducttypes()
            this.fetchtemplates()
        },
        computed: {
            filteredproducts() {
                if (this.filter.published != null)
                    return this.products.filter(p => p.published == this.filter.published)
                else
                    return this.products
            }
        }
    }
</script>
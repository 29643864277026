<template>
    <div class="bool-tick">
        <label :class="{on: value==true}" class="yes"><input @input="$emit('update:modelValue', true)" v-model="value" :value="true" type="radio" /> Yes</label>
        <label :class="{on: value==false}" class="no">No <input @input="$emit('update:modelValue', false)" v-model="value" :value="false" type="radio" /></label>
    </div>
</template>

<script>
    export default {
        name: 'BoolTick',
        props: ['modelValue'],
        emits: ['update:modelValue'],
        data() {
            return {
                value: this.modelValue
            }
        }
    }
</script>
<style>
    .bool-tick { display: flex; background-color: #ebebeb; 
                 width: 6rem; height: 2.5rem;
                 border-radius: 0.5rem; overflow: hidden; }
    .bool-tick input[type="radio"] {
        visibility: hidden;
        font-size: 1.5rem;
        width: 0; height: 1.25rem;
    }
    .bool-tick label { 
        text-align: center; display: flex;     
        align-items: center;
        width: 50%;
        cursor: pointer;
        align-content: center;
        justify-content: center; 
    }
    .yes.on { background-color: green; color: #FFF; cursor: initial; }
    .no.on { background-color: red; color: #FFF; cursor: initial; }
</style>

<template>
    <div class="container">
        Homepage
    </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

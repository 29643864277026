<template>
    <iframe src='https://www.colourtheirday.com/Home/ClearCache?key=currentoffers'></iframe>
</template>

<script>
    export default {
        name: 'ClearOfferCache',
        data() {
            return {
            }
        },
        components: {

        },
        methods: {

        }
    }
</script>
<style>
    iframe { width: 100%; height: 80vh; }
</style>
<template>
    <div class="container offer-templater">
        <div :class="{'is-active': template != null}" class="modal">
            <div class="modal-background" @click="$emit('close-templater')"></div>
            <form @submit.prevent="saveoffer" class="modal-card">
                <div class="modal-card-head">
                    <p class="modal-card-title">New {{template}}</p>
                    <button  @click="$emit('close-templater')" class="modal-close is-large" aria-label="close"></button>
                </div>
                <div class="modal-card-body">
                    <template v-if="offer != null">
                        <div v-if="template == 'moneyoffpromo' && offer.offerPromoCodeQualifiers" class="columns">
                            <div class="column">
                                <div v-for="(q, ind) in offer.offerPromoCodeQualifiers" :key="ind">
                                    <div v-for="pcq in q.qualifyingPromoCodes">
                                        <div class="field">
                                            <label class="label">Code</label>
                                            <input required ref="newcode" class="input is-large" @input="(ev) => pcq.promoCode.name = ev.target.value" v-model="pcq.promoCode.string" />
                                        </div>
                                        <div class="field">
                                            <label class="label">Active From</label>
                                            <input required  @input="(ev) => offer.startDate = ev.target.value" class="input" type="datetime-local" v-model="pcq.promoCode.startDate" />
                                        </div>
                                        <div class="field">
                                            <label class="label">Active To</label>
                                            <input required @input="(ev) => offer.endDate = ev.target.value" class="input" type="datetime-local" v-model="pcq.promoCode.endDate" />
                                        </div>
                                        <div class="field">
                                            <label class="label">Headline</label>
                                            <input required  @input="(ev) => {offer.headline = ev.target.value; offer.name=ev.target.value}" class="input" v-model="pcq.promoCode.headline" />
                                        </div>
                                        <div class="field">
                                            <label class="label">Max Redemptions</label>
                                            <input required type="number" class="input" v-model="pcq.promoCode.maxRedemptions" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="column">
                                <template v-for="oq in offer.offerQualifiers">
                                    <div class="field">
                                        <label class="label">Percentage Off</label>
                                        <input required type="number" step="0.01" min="0.01" class="input is-large" v-model="oq.discountValue" />
                                    </div>

                                </template>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="modal-card-foot">
                    <button @click="$emit('closeTemplater')" class="button">Cancel</button>
                    <button class="button is-primary">Create Offer</button>
                    <p>
                        The offer will be created but not be public. <br />You can add other rules and check it next.

                    </p>
                </div>
            </form>
        </div>
    </div>
</template>
<style>

</style>
<script>
    import axios from 'axios'
    //import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    //import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            template: String
        },
        components: {
            //YesNoBlob, BoolTick
        },
        data() {
            return {
                searchq: null,
                products: [],
                addproducts: [],
                addcats: [],
                addpromos: [],
                offer: null,
                now: null,
                nowplusmonth: null,
                templates: {
                    moneyoffsale: {
                        offerType: 1,
                        offerQualifiers: [
                            {
                                assureBoolean: true,
                                cheapestFirst: false,
                                discountValue: 0,
                                discountedQuantity: 1,
                                requiredQuantity: 1,
                                id: 0,
                                offerOptionQualifiers: [],
                                offerSkuPrices: [],
                                qualifyingCategories: [],
                                qualifyingProducts: [],
                                qualifyingSkus: [],
                            }
                        ]
                    },
                    qualifyingpromo: {                      
                        id: 0,
                        offerPromoCodeQualifierId: 0,
                        promoCodeId: 0,
                        promoCode: { }                
                    },
                    moneyoffpromo: {
                        offerType: 1,
                        offerQualifiers: [
                            {
                                assureBoolean: true,
                                cheapestFirst: false,
                                discountValue: 0,
                                discountedQuantity: 1,
                                requiredQuantity: 1,
                                id: 0,
                                offerOptionQualifiers:[],
                                offerSkuPrices:[],
                                qualifyingCategories:[],
                                qualifyingProducts:[],
                                qualifyingSkus: [],
                            }
                        ],
                        offerPromoCodeQualifiers: [
                            {
                                id: 0,
                                qualifyingPromoCodes: [
                                    {
                                        id: 0,
                                        promoCode: {
                                            description: 'NEW PROMO CODE',
                                            endDate: null,
                                            guestNote: null,
                                            headline: null,
                                            id: 0,
                                            maxRedemptions: 1000,
                                            name: null,
                                            note: null,
                                            published: true,
                                            startDate: null,
                                            status: 1,
                                            string: 'NEWPROMO',
                                            totalRedemptions: 0
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    freedelivery: {
                        offerType: 10,
                        offerQualifiers: [
                            {
                                assureBoolean: true,
                                cheapestFirst: false,
                                discountValue: 0,
                                discountedQuantity: 1,
                                requiredQuantity: 1,
                                id: 0,
                                offerOptionQualifiers: [],
                                offerSkuPrices: [],
                                qualifyingCategories: [],
                                qualifyingProducts: [],
                                qualifyingSkus: [],
                            }
                        ],
                        deliveryPricePolicyQualifiers: [
                            {
                                assureBoolean: true,
                                id: 0,
                                qualifyingDestinations: [
                                    {
                                        deliveryPricePolicyQualifierId: 0,
                                        destinationId: 1,
                                        destination: { name:'UK', id: 1 },
                                        id: 0
                                    },
                                    {
                                        deliveryPricePolicyQualifierId: 0,
                                        destinationId: 2,
                                        destination: { name: 'Channel Islands', id: 2 },
                                        id: 0
                                    },
                                    {
                                        deliveryPricePolicyQualifierId: 0,
                                        destinationId: 3,
                                        destination: { name: 'Isle of Man', id: 3 },
                                        id: 0
                                    }
                                ],
                                qualifyingPriceTiers: [{
                                    deliveryPricePolicyQualifierId: 0,
                                    id: 0,
                                    priceTier: { name: 'Standard', id: 1, sequence: 1, status: 1 },
                                    priceTierId: 1
                                }]
                            }
                        ]
                    }
                }
            }
        },
        watch: {
            template(nt) {
                this.makeblank(nt)  
            }
        },
        mounted() {
            //this.getoffer(0)
            this.now = new Date();
            let copy = new Date(this.now.getTime())

            copy.setMonth(copy.getMonth() + 1)
            this.nowplusmonth = copy

            //this.templates.moneyoffpromo.offerPromocodeQualifiers[0].qualifyingPromoCodes[0].promoCode.startDate = this.now
            //this.templates.moneyoffpromo.offerPromocodeQualifiers[0].qualifyingPromoCodes[0].promoCode.endDate = this.nowplusmonth

        },
        methods: {
            searchproduct(q) {

            },
            makeblank(t) {
                axios.get(`/api/offers/0`)
                    .then((response) => {
                        this.offer = Object.assign({}, response.data);
                        this.offer.fromDate = this.now
                        this.offer.toDate = this.nowplusmonth

                        let tmp = Object.assign({}, this.templates[t])
                        Object.assign(this.offer, tmp)

                        switch (t) {
                            case 'moneyoffpromo':
                                let prom = this.offer.offerPromoCodeQualifiers[0].qualifyingPromoCodes[0].promoCode
                                prom.startDate = this.now.toJSON().substr(0, 16)
                                prom.endDate = this.nowplusmonth.toJSON().substr(0, 16)

                                break
                        }

                        if (this.$refs['newcode'])
                            this.$nextTick(() => this.$refs['newcode'].select())
                    })
                    .then()
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            saveoffer() {
                axios.post('/api/offers', this.offer)
                    .then((response) => {
                        if (response && response.data) {
                            this.$emit('doSaveTemplated', response.data)
                            this.off = response.data
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getoffer(id) {
                axios.get(`/api/offers/${id}`)
                    .then((response) => {
                        this.offer = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    }
</script>

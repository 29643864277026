

<template>
    <div class="container">

        <div class="product-edit">
            <div v-if="prod">
                <div class="columns">
                    <div class="column is-one-third content scrolly-images-col">
                        <div class="columns">
                            <h2 class="column">Images</h2>
                            <div class="column is-narrow">
                                <button @click="showupload=true" class="button is-warning">Add Image</button>
                            </div>
                        </div>
                        <form @submit.prevent="doupload" v-if="showupload" class="upload-form">
                            Save product before uploading images
                            <div class="file">
                                <label class="file-label">
                                    <input @input="dochange" multiple class="file-input" type="file" name="resume">
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">
                                            Choose a file…
                                        </span>
                                    </span>
                                </label>
                                <input class="button ml-2" type="submit" value="Upload" />
                            </div>
                        </form>
                        <transition-group name="fade" tag="span">
                            <div class="prodimg" :class="{deleted: img.status==-1}" v-for="img in sortedimages" :key="img">
                                <div class="columns">
                                    <div class="imgpad column is-narrow has-hhbackground-white">
                                        <img loading="lazy" width="64" height="64" :src="`${imgbase}/${img.slug}?format=webp&width=128`" />
                                    </div>
                                    <div class="column field">
                                        <label class="label">Alt text #{{img.id}}</label>
                                        <input class="input" type="text" v-model="img.alt" />
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <label class="label">Sequence</label>
                                        <div class="field has-addons">
                                            <p class="control">
                                                <a @click="img.sequence--" class="button">-</a>
                                            </p>
                                            <p class="control">
                                                <input class="input skinny" type="number" v-model="img.sequence" />
                                            </p>
                                            <p class="control">
                                                <a @click="img.sequence++" class="button">+</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <label class="label">Status</label>
                                        <div class="select">
                                            <select v-model="img.status">
                                                <option :value="0">Hidden</option>
                                                <option :value="1">Active</option>
                                                <option :value="-1">Deleted</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </div>

                    <div class="column content">
                        <div class="columns">
                            <div class="column">
                                <h2>Details</h2>
                            </div>

                            <div class="column is-narrow">
                                <label class="label">ID</label>
                                {{prod.id}}
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Created</label>
                                {{prod.createdDate}}
                            </div>

                            <!-- NotSet = 0, Standard = 1, SpecialOrder = 2, Clearance = 3 -->
                        </div>
                        <!--
                                availability: true,
                                publishing: true,
                                delivery: false,
                                preorder: true,
                                settings: false
                        -->
                        <div class="field">
                            <div class="columns">
                                <div class="column is-narrow">
                                    <label class="label">Published</label>
                                    <bool-tick v-model="prod.published" />
                                </div>
                                <div class="column is-narrow">
                                    <label class="label">On Date</label>
                                    <input class="input" type="datetime-local" v-model="prod.onDate" />
                                </div>
                                <div class="column">
                                    <label class="label">Off Date</label>
                                    <input class="input" type="datetime-local" v-model="prod.offDate" />
                                </div>
                            <!--
                            </div>
                        </div>
                        <div class="field">
                            <div class="columns">
                            -->
                        
                                <div class="column is-narrow">
                                    <label class="label">Status</label>
                                    <div class="select">
                                        <select v-model="prod.status">
                                            <option :value="0">Hidden</option>
                                            <option :value="1">Active</option>
                                            <option :value="-1">Deleted</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="column is-narrow">
                                    <label class="label">Stock Type</label>
                                    <div class="select">
                                        <select v-model="prod.stockType">
                                            <option :value="0">Not Set</option>
                                            <option :value="1">Standard</option>
                                            <option :value="2">Special Order</option>
                                            <option :value="3">Clearance</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="field columns">
                            <div class="column">
                                <label class="label">Name</label>
                                <input @input="parseslug" v-model="prod.name" class="input" />
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Wholesale</label>
                                <bool-tick v-model="prod.isWholesale" />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Slug</label>
                            <input required v-model="prod.slug" class="input" />
                        </div>
                        <div class="field">
                            <label class="label">Title</label>
                            <input v-model="prod.title" class="input" />
                        </div>
                        <div class="field">
                            <textarea class="textarea" v-model="prod.description" rows="12"></textarea>
                        </div>
                        <div class="field columns">
                            <div class="column">
                                <label class="label">Product Type</label>
                                <div v-if="types" class="select">
                                    <select v-model="prod.productTypeId">
                                        <option v-for="opt in types" :key="opt.id" :value="opt.id">{{opt.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="column">
                                <label class="label">Sku Template</label>
                                <div v-if="templates" class="select">
                                    <select v-model="prod.skuTemplateId">
                                        <option :value="null">Select template...</option>
                                        <option v-for="opt in templates" :key="opt.id" :value="opt.id">{{opt.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="content columns">
                            <div class="column">
                                <h2>Personalisation</h2>

                                <div class="field has-addons">
                                    <p class="control">
                                        <span class="select">
                                            <select v-model="selectedpersonalisationset">
                                                <option :value="null" disabled>Select a Personalisation...</option>
                                                <option v-for="(ps,i) in personalisationsets" :key="i" :value="ps">{{ps.name}}</option>
                                            </select>
                                        </span>
                                    </p>
                                    <p class="control">
                                        <a @click="addpersonalisationtoproduct" :class="{'is-disabled': selectedpersonalisationset==null}" class="button">Add to Product</a>
                                    </p>
                                </div>
                                <transition-group name="fade" tag="div">
                                    <div v-for="(ps, i) in sortedpersonalisationsets" :key="i" class="columns">
                                        <div class="column">
                                            <label class="label">Set Name</label>
                                            <div>
                                                #{{ps.personalisationSet.id}} {{ps.personalisationSet.name}}
                                            </div>
                                        </div>
                                        <div class="column">
                                            <label class="label">Personalisations</label>
                                            <div v-for="(p,i) in ps.personalisationSet.personalisationSetPersonalisations" :key="i">
                                                {{p.personalisation.name}}
                                            </div>

                                        </div>
                                        <div class="column">
                                            <label class="label">Sequence</label>
                                            <div class="field has-addons">
                                                <p class="control">
                                                    <a @click="ps.sequence--" class="button">-</a>
                                                </p>
                                                <p class="control">
                                                    <input class="input skinny" step="10" type="number" v-model="ps.sequence" />
                                                </p>
                                                <p class="control">
                                                    <a @click="ps.sequence++" class="button">+</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <span @click="removepersonalisation(i)" class="delete"></span>
                                        </div>
                                    </div>
                                </transition-group>
                            </div>
                            <div class="column">
                                <h2>Categories</h2>
                                <div v-for="(pc, i) in prod.categoryProducts" class="prodcat-row" :key="i">
                                    <div class="columns">
                                        <div :title="pc.categoryId" class="column">{{pc.category.name}}</div>
                                        <div class="column is-narrow">
                                            <input class="input is-small rem4-input" v-model="pc.sequence" />
                                        </div>
                                        <div class="column is-narrow">
                                            <button @click="deleteCategoryProduct(pc.categoryId)" class="delete"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <button @click="fetchcategories" class="button">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content columns">
                            <div class="column">
                                <label class="label">Alias URLs</label>
                                <div v-for="a in prod.aliasProducts">/{{a.alias_value}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field content">
                    <h2 class="">Skus</h2>
                    <div class="tabs is-centered">
                        <ul>
                            <li @click="skutab='availability'" :class="{'is-active':skutab=='availability'}">
                                <a>Availability</a>
                            </li>
                            <li @click="skutab='preorder'" :class="{'is-active':skutab=='preorder'}">
                                <a>Pre-Order</a>
                            </li>
                            <li @click="skutab='publishing'" :class="{'is-active':skutab=='publishing'}">
                                <a>Publishing</a>
                            </li>
                            <li @click="skutab='delivery'" :class="{'is-active':skutab=='delivery'}">
                                <a>Delivery</a>
                            </li>
                            <li @click="skutab='settings'" :class="{'is-active':skutab=='settings'}">
                                <a>Settings</a>
                            </li>
                        </ul>
                    </div>
                    <div class="field has-text-centered is-horizontal">
                        <div class="field-label is-normal">
                            <label v-if="skutab=='availability'" class="checkbox">
                                <input type="checkbox" :value="true" v-model="skulink.availability" />
                                Copy values to all
                            </label>
                            <label v-if="skutab=='publishing'" class="checkbox">
                                <input type="checkbox" :value="true" v-model="skulink.publishing" />
                                Copy values to all
                            </label>
                            <label v-if="skutab=='delivery'" class="checkbox">
                                <input type="checkbox" :value="true" v-model="skulink.delivery" />
                                Copy values to all
                            </label>
                            <label v-if="skutab=='preorder'" class="checkbox">
                                <input type="checkbox" :value="true" v-model="skulink.preorder" />
                                Copy values to all
                            </label>
                            <label v-if="skutab=='settings'" class="checkbox">
                                <input type="checkbox" :value="true" v-model="skulink.settings" />
                                Copy values to all
                            </label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <button @click="addSku" class="button">NEW Sku</button>
                            </div>
                        </div>
                    </div>
                    <div v-for="(sku, i) in prod.skus.filter((s)=>s.status > -1)" :key="i" class="is-striped p-2">
                        <div class="columns is-mobile">
                            <div v-if="sku.skuProductImages.length" class="column is-narrow">
                                <img v-for="(img, i) in sku.skuProductImages" loading="lazy" width="64" height="64" :src="`${imgbase}/${img.productImage.slug}?format=webp&width=128`" />
                                <br />
                                <button @click="editskuimages(sku)" class="button is-small">Images</button>
                            </div>
                            <div v-else-if="prod.productImages.length" class="column is-narrow">
                                <img loading="lazy" width="64" height="64" :src="`${imgbase}/${sortedimages[0].slug}?format=webp&width=128`" />
                                <br />
                                <button @click="editskuimages(sku)" class="button is-small">Images</button>
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Id</label>
                                {{sku.id}}
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Code</label>
                                <input class="input skinny" v-model="sku.code" />
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Name</label>
                                    <input class="input" v-model="sku.name" />
                                </div>
                                <div class="tags">
                                    <span class="tag is-warning" v-for="(ao, i) in sku.skuOptions" :key="i">#{{ao.attributeOption.id}} {{ao.attributeOption.attribute.name}}:&nbsp;<strong>{{ao.attributeOption.option.name}}</strong><button @click="deleteAttributeOptionFromSku(ao.attributeOption, sku)" class="delete"></button></span>
                                </div>
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Sequence</label>
                                <input type="number" class="input skinny" v-model="sku.sequence" />
                            </div>
                            <div v-if="skutab=='publishing'" class="column is-narrow">
                                <label class="label">Published</label>
                                <!-- @update:modelValue="(value) => copyall('publishing', 'published', value)" -->
                                <bool-tick v-model="sku.published"></bool-tick>
                            </div>
                            <div v-if="skutab=='availability'" class="column is-narrow">
                                <label class="label">Prices</label>
                                <div class="field has-addons mb-0">
                                    <p class="control mb-0">
                                        <span class="button is-static">Retail</span>
                                    </p>
                                    <p v-if="sku.skuPrices[0]" class="control mb-0">
                                        <input type="number" class="input skinny" v-model="sku.skuPrices[0].price" />
                                    </p>
                                    <p class="control">
                                        <span class="button is-static">Trade</span>
                                    </p>
                                    <p v-if="sku.skuPrices[1]" class="control">
                                        <input type="number" class="input skinny" v-model="sku.skuPrices[1].price" />
                                    </p>
                                </div>
                            </div>
                            <div v-if="skutab=='availability'" class="column is-narrow">
                                <label class="label">Stock</label>
                                <input type="number" :class="{'is-danger': sku.stock <= 0}" class="input skinny" v-model="sku.stock" />
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Status</label>
                                <div class="select">
                                    <select v-model="sku.status">
                                        <option :value="0">Hidden</option>
                                        <option :value="1">Active</option>
                                        <option :value="-1">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Stock Type</label>
                                <div class="select">
                                    <select v-model="sku.stockType">
                                        <option :value="0">Not Set</option>
                                        <option :value="1">Standard</option>
                                        <option :value="2">Special Order</option>
                                        <option :value="3">Clearance</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="skutab=='publishing'" class="column is-narrow">
                                <label class="label">On Date</label>
                                <input @input="copyall('publishing', 'onDate', $event)" type="datetime-local" class="input " v-model="sku.onDate" />
                            </div>
                            <div v-if="skutab=='publishing'" class="column is-narrow">
                                <label class="label">Off Date</label>
                                <input @input="copyall('publishing', 'offDate', $event)" type="datetime-local" class="input " v-model="sku.offDate" />
                            </div>
                            <div v-if="skutab=='delivery'" class="column is-narrow">
                                <label class="label">Dimensions <span class="has-text-weight-normal">(X.Y.Z)</span></label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <input type="number" class="input   skinny" v-model="sku.dimensionX" />
                                    </p>
                                    <p class="control">
                                        <input type="number" class="input   skinny" v-model="sku.dimensionY" />
                                    </p>
                                    <p class="control">
                                        <input type="number" class="input   skinny" v-model="sku.dimensionZ" />
                                    </p>

                                </div>
                            </div>
                            <div v-if="skutab=='delivery'" class="column is-narrow">
                                <label class="label">Weight</label>
                                <input type="number" class="input skinny " v-model="sku.packagedWeight" />
                            </div>
                            <div v-if="skutab=='availability'" class="column is-narrow">
                                <label class="label">Backorder</label>
                                <bool-tick v-model="sku.allowBackOrder"></bool-tick>
                            </div>
                            <div v-if="skutab=='settings'" class="column is-narrow">
                                <label class="label">Removed From Sale</label>
                                <bool-tick v-model="sku.removedFromSale"></bool-tick>
                            </div>
                            <div v-if="skutab=='preorder'" class="column is-narrow">
                                <label class="label">Pre-order</label>
                                <bool-tick v-model="sku.allowPreOrder"></bool-tick>
                            </div>
                            <div v-if="skutab=='preorder'" class="column is-narrow">
                                <label class="label">Starts</label>
                                <input @input="copyall('preorder', 'preOrderOnDate', $event)" type="datetime-local" class="input is-small" v-model="sku.preOrderOnDate" />
                            </div>
                            <div v-if="skutab=='preorder'" class="column is-narrow">
                                <label class="label">Ends</label>
                                <input @input="copyall('preorder', 'preOrderOffDate', $event)" type="datetime-local" class="input is-small" v-model="sku.preOrderOffDate" />
                            </div>
                            <div v-if="skutab=='preorder'" class="column is-narrow">
                                <label class="label">Stock Expected</label>
                                <div class="field has-addons">
                                    <p class="control">
                                        <input @input="copyall('preorder', 'stockExpectedFromDate', $event)" type="datetime-local" class="input is-small"
                                               v-model="sku.stockExpectedFromDate" />
                                    </p>
                                    <p class="control">
                                        <span class="button  is-static is-small">to</span>
                                    </p>
                                    <p class="control">
                                        <input @input="copyall('preorder', 'stockExpectedToDate', $event)" type="datetime-local" class="input is-small"
                                               v-model="sku.stockExpectedToDate" />
                                    </p>
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <button @click="sku.status=-1" class="delete"></button>
                            </div>

                            <div v-if="!selectedsku || selectedsku.code != sku.code" class="column is-narrow">
                                <button @click="selectedsku=sku" class="button is-small">Edit</button>
                            </div>
                            <div v-else class="column is-narrow">
                                <button @click="selectedsku=null" class="button is-primary is-small">Done</button>
                            </div>

                        </div>
                        <div class="columns">
                            <div v-if="selectedsku && selectedsku.code == sku.code" class="column field has-addons">
                                <p class="control">
                                    <span class="select">
                                        <select @change="attributeOptionToAdd = null;attributeOptionToCreate = null" v-model="attributeToAdd">
                                            <option :value="null" disabled>Select Attribute...</option>
                                            <option v-for="(at,i) in attributes.sort(function(a, b) { return a.name.localeCompare(b.name);})" :key="i" :value="at">{{at.name}}</option>
                                        </select>
                                    </span>
                                </p>
                                <p v-if="attributeToAdd != null && attributeOptionToCreate == null" class="control">
                                    <span class="select">
                                        <select v-model="attributeOptionToAdd">
                                            <option :value="null" disabled>Select Option...</option>
                                            <option v-for="(ao,i) in attributeToAdd.attributeOptions.sort(function(a, b) { return a.option.label.localeCompare(b.option.label);})" :key="i" :value="ao">{{ao.option.label}}</option>
                                        </select>
                                    </span>
                                </p>
                                <p v-if="attributeOptionToCreate != null" class="control">
                                    <input :ref="`${sku.code}_opt`" class="input" v-model="attributeOptionToCreate.option.label" />
                                </p>
                                <template v-if="attributeToAdd != null">
                                    <p v-if="attributeOptionToCreate == null" class="control">
                                        <a @click="creatNewAttributeOption" class="button">NEW Option</a>
                                    </p>
                                    <p v-else class="control">
                                        <a @click="addNewAttributeOptionToAttribute" class="button is-primary">ADD</a>
                                    </p>
                                </template>
                                <p v-if="attributeOptionToAdd != null" class="control">
                                    <a @click="addAttributeOptionToSku" :class="{'is-disabled': attributeToAdd==null||attributeOptionToAdd == null}" class="button is-primary">Add to Product</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="has-background-light">
        <div class="columns">
            <div class="buttons">
                <button class="button" @click="$router.push('/Products')">Cancel</button>
                <button class="button is-primary" @click="validate">SAVE</button>
                <button style="margin-left:auto;" class="button" @click="cloneproduct(prod.id)">Duplicate</button>
                <span :class="{saving: savestatus == 'saving', saved: savestatus=='saved'}" class="status-msg">{{savemessage[savestatus]}}</span>
            </div>
        </div>
    </footer>
    <div :class="{'is-active': showcats==true}" class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
            <div class="modal-card-head">
                <p class="modal-card-title">Add to Categories</p>
                <button @click="showcats=false" class="modal-close is-large" aria-label="close"></button>
            </div>
            <div class="modal-card-body">
                <ul class="content">
                    <li v-for="(c,i) in categories" :key="i">
                        <label class="cat-name"><input type="checkbox" :value="c.id" v-model="addingcats" /> {{c.name}}</label>
                        <ul v-if="c.children.length">
                            <li v-for="(cc,i) in c.children" :key="i">
                                <label class="cat-name"><input type="checkbox" @change="parentselect(cc)" :value="cc.id" v-model="addingcats" /> {{cc.name}}</label>
                                <ul v-if="cc.children.length">
                                    <li v-for="(ccc,i) in cc.children" :key="i">
                                        <label class="cat-name"><input type="checkbox" @change="parentselect(ccc)" :value="ccc.id" v-model="addingcats" /> {{ccc.name}}</label>
                                        <ul v-if="ccc.children.length">
                                            <li v-for="(cccc,i) in ccc.children" :key="i">
                                                <label class="cat-name"><input type="checkbox" @change="parentselect(cccc)" :value="cccc.id" v-model="addingcats" /> {{cccc.name}}</label>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <footer class="modal-card-foot">
                <button @click="showcats=false" class="button">Cancel</button>
                <button @click="processaddingcats" :disabled="addingcats.length==0" class="button is-success">Add Selection</button>
            </footer>
        </div>
    </div>

    <div :class="{'is-active': showskuimg==true}" class="modal">
        <div @click="showskuimg=false" class="modal-background"></div>
        <div class="modal-card">
            <div class="modal-card-head">
                <p class="modal-card-title">Edit Sku Images</p>
                <button @click="showskuimg=false" class="modal-close is-large" aria-label="close"></button>
            </div>
            <div class="modal-card-body">
                <template v-if="selectedsku && selectedsku.skuProductImages.length">
                    <div v-for="(img, i) in sortedskuimages" class="skuimgedit columns">
                        <div class="column is-narrow">
                            <img loading="lazy" width="64" height="64" :src="`${imgbase}/${img.productImage.slug}?format=webp&width=128`" />
                        </div>
                        <div class="column">
                            <input class="input" type="number" step="1" v-model="img.sequence" />
                        </div>
                        <div class="column is-narrow">
                            <button class="delete is-large" @click="deleteskuimage(selectedsku, img)"></button>
                        </div>
                    </div>
                </template>
                <button class="button is-primary" @click="showimageselection = true">Add Image</button>
                <div class="mt-4" v-if="showimageselection==true">
                    <img class="addableimg" @click="addskuimage(selectedsku, img)" v-for="(img, i) in prod.productImages.filter(i=>selectedsku.skuProductImages.every(si=>si.productImageId !== i.id))" loading="lazy" width="64" height="64" :src="`${imgbase}/${img.slug}?format=webp&width=128`" />
                    <p>
                        <small>Click an image to add it</small>
                    </p>
                </div>
            </div>
            <div class="modal-card-foot">
                <button class="button" @click="showskuimg=false;showimageselection=false">Done</button>
            </div>
        </div>
    </div>


</template>
<style>
    .status-msg {
        align-self: center;
        margin-bottom: 0.5rem;
        padding-bottom: calc(0.5em - 1px);
        padding-left: 1em;
        padding-right: 1em;
        padding-top: calc(0.5em - 1px);
        font-weight: bold;
    }
        .status-msg.saved::before {
            content: '\2713';
            margin-right: 0.25rem;
            display: inline-block;

        }
        .status-msg.saving::before {
            content: '\260D';
            margin-right: 0.25rem;
            display: inline-block;
        }
        .status-msg.saved {
            color: #0c0;
        }
    .status-msg.saving {
        color: #ccc;
    }
    .status-msg.error {
        color: #f00;
    }
    .addableimg {
        margin: 0.25rem;
        border: 2px solid #ebebeb;
        cursor: pointer;
    }

    .cat-lists {
        background: #fff;
        height: 90vh;
    }

    .cat-name {
        padding: 0.5rem;
    }

    .content ul {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }

    .menu-line {
        position: relative;
        width: 16rem;
    }

    .d1 {
    }

    .d2 {
        display: none;
        position: absolute;
        top: 0;
        left: 16rem;
    }

    .d3 {
        display: none;
        position: absolute;
        top: 0;
        left: 16rem;
    }

    .d4 {
        display: none;
        position: absolute;
        top: 0;
        left: 16rem;
    }

    .menu-line > .menu-line {
    }

    .cat-name.active + .d1,
    .cat-name.active + .d2,
    .cat-name.active + .d3,
    .cat-name.active + .d4 {
        display: block;
    }

    .upload-form {
        padding: 0 0 2rem;
    }

    .product-edit {
        margin-top: 2rem;
        margin-bottom: 8rem;
    }

    input.skinny {
        width: 5rem;
    }

    .prodcat-row {
        border: 1px solid #ebebeb;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .rem4-input {
        width: 4rem;
    }
    /* 1. declare transition */
    .fade-move,
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }

    /* 2. declare enter from and leave to state */
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform: scaleY(0.01) translate(30px, 0);
    }

    /* 3. ensure leaving items are taken out of layout flow so that moving
          animations can be calculated correctly. */
    .fade-leave-active {
        position: absolute;
    }

    .prodimg.deleted {
        opacity: 0.3;
        transform: scale(0.75);
        height: 9rem;
    }

    .prodimg {
        padding: 0.5rem;
    }

        .prodimg:nth-child(odd) {
            background-color: #f7f7f7;
        }

    .scrolly-images-col {
        max-height: 70rem;
        overflow: auto;
    }
</style>
<script>
    import axios from 'axios'
    import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {
            searchterm: String
        },
        emits: ['cancelSave', 'doSave'],
        name: "ProductEdit",
        components: {
            YesNoBlob, BoolTick
        },
        computed: {
            sortedskuimages() {
                if (this.selectedsku && this.selectedsku.skuProductImages)
                    return this.selectedsku.skuProductImages.sort((a, b) => a.sequence - b.sequence)

                return []
            },
            sortedimages() {
                return this.prod.productImages.filter(i => i.status != -1).sort((a, b) => a.sequence - b.sequence)
            },
            sortedpersonalisationsets() {
                return this.prod.productPersonalisationSets.sort((a, b) => a.sequence - b.sequence)
            },
            flatcategories() {
                let fla = []
                if (this.categories && this.categories.length)
                    this.categories.forEach((v) => {
                        fla.push(v)
                        v.children.forEach((c) => {
                            fla.push(c)
                            c.children.forEach((cc) => {
                                fla.push(cc)
                                cc.children.forEach((ccc) => {
                                    fla.push(ccc)
                                    ccc.children.forEach((cccc) => {
                                        fla.push(cccc)
                                    })
                                })
                            })
                        })
                    })
                return fla
            }
        },
        data() {
            return {
                prod: null,
                savestatus: 'done',
                savemessage: { done: '', error: 'ERROR', saving: 'saving..', saved: 'saved'},
                skutab: 'availability',
                imgbase: `${this.$host}/images/product/`,
                uploadurl: this.$host,
                skulink: {
                    availability: true,
                    publishing: true,
                    delivery: false,
                    preorder: true,
                    settings: false
                },
                showupload: false,
                files: null,
                personalisationsets: [],
                selectedpersonalisationset: null,
                categories: [],
                types: [],
                templates: [],
                showcats: false,
                showatts: true,
                addingcats: [],
                attributes: [],
                attributeToAdd: null,
                attributeOptionToAdd: null,
                attributeOptionToCreate: null,
                showskuimg: false,
                showimageselection: false,
                selectedsku: null,
                selectedskuimages: null
            }
        },
        mounted() {
            this.getproduct(this.$route.params.id)
            this.fetchproducttypes()
            this.fetchtemplates()
            this.fetchpersonalisationsets()
            this.fetchAttributes()
        },
        methods: {
            validate(event) {
                if (this.prod.slug && this.prod.name)
                    this.saveproduct();
                else {
                    let req = document.querySelectorAll('input.input[required]')
                    req.forEach((el) => {
                        el.classList.remove('is-danger')
                        if (!el.value)
                            el.classList.add('is-danger')
                        else
                            el.classList.remove('is-danger')
                    })
                    req[0].focus()
                }

            },
            dochange(event) {
                this.files = event.target.files
            },
            removepersonalisation(ind) {
                this.prod.productPersonalisationSets.splice(ind, 1)
            },
            addpersonalisationtoproduct() {
                if (this.selectedpersonalisationset == null)
                    return

                let sel = this.selectedpersonalisationset
                //let sets = this.prod.productPersonalisationSets
                if (this.prod.productPersonalisationSets.findIndex((s) => s.id == sel.id) == -1) {
                    let l = this.prod.productPersonalisationSets.length
                    this.prod.productPersonalisationSets.push({
                        id: 0,
                        personalisationSetId: sel.id,
                        productId: this.prod.id,
                        personalisationSet: sel,
                        sequence: (l * 10) + 10
                    })
                }
                else
                    return false
            },
            doupload() {
                const formData = new FormData();
                formData.set('productId', this.prod.id)
                for (const i of Object.keys(this.files)) {
                    formData.append('files', this.files[i])
                }
                axios.post(`${this.$host}/admin/upload/uploadproductimage`, formData, {
                }).then((res) => {
                    this.getproduct(this.prod.id)
                })
            },
            getproduct(id) {
                axios.get(`/api/product/${id}`)
                    .then((response) => {
                        this.$emit('doSave', response.data)
                        this.prod = response.data
                        this.prod.skus.forEach((sk) => {
                            if (sk.skuPrices.length === 0) {
                                sk.skuPrices.push({
                                    id: 0,
                                    skuId: sk.id,
                                    currencyId: 1,
                                    priceListId: 1,
                                    price: 999.99,
                                    breakquantity: 0
                                });
                                sk.skuPrices.push({
                                    id: 0,
                                    skuId: sk.id,
                                    currencyId: 1,
                                    priceListId: 2,
                                    price: 999.99,
                                    breakquantity: 0
                                })
                            }
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchpersonalisationsets() {
                axios.get('/api/product/personalisationsets')
                    .then((response) => {
                        this.personalisationsets = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchproducttypes() {
                axios.get('/api/product/types')
                    .then((response) => {
                        this.types = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchtemplates() {
                axios.get('/api/product/templates')
                    .then((response) => {
                        this.templates = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            copyall(tab, prop, event) {
                if (this.skutab == tab && this.skulink[tab] == true) {
                    let skus = this.prod.skus
                    let val = event.target ? event.target.value : event
                    skus.forEach((sk) => {
                        sk[prop] = val
                    })

                }

            },
            saveproduct() {
                this.savestatus = 'saving'
                axios.post('/api/product', this.prod)
                    .then((response) => {
                        if (response && response.data) {
                            response.data.productPersonalisationSets.forEach(pps => {
                                if (pps.personalisationSet == null) {
                                    let tpps = this.personalisationsets.find(el => el.id == pps.personalisationSetId)
                                    if (tpps)
                                        pps.personalisationSet = tpps
                                }
                            })
                            this.$emit('doSave', response.data)
                            this.prod = response.data
                            this.savestatus = 'saved'
                            console.log('saved')
                        }
                        else {
                            console.log('post did not get a response!')
                            this.savestatus = 'error'
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.savestatus = 'error'
                    });
            },
            parseslug() {
                if (this.prod.id == 0) {
                    let slug = this.prod.name.trim().toLowerCase().replace(/[^a-z]+/gi, '-')

                    this.prod.slug = slug
                }
            },
            fetchcategories() {
                axios.get('/api/category')
                    .then((response) => {
                        this.categories = response.data

                        this.categories.forEach((c) => {
                            c.children.forEach((cc) => {
                                cc.parent = c
                                cc.children.forEach((ccc) => {
                                    ccc.parent = cc
                                    ccc.children.forEach((cccc) => {
                                        cccc.parent = ccc
                                    })
                                })
                            })
                        })

                        this.showcats = true
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            parentselect(c) {
                if (this.addingcats.includes(c.id)) {
                    if (c.parent) {
                        this.addingcats.push(c.parent.id)
                        if (c.parent.parent) {
                            this.addingcats.push(c.parent.parent.id)
                            if (c.parent.parent.parent) {
                                this.addingcats.push(c.parent.parent.parent.id)
                            }
                        }
                    }
                } else
                    this.addingcats = []
            },
            deleteCategoryProduct(cid) {
                let ind = this.prod.categoryProducts.findIndex(c => c.categoryId == cid)
                this.prod.categoryProducts.splice(ind, 1)
            },
            processaddingcats() {
                this.addingcats.forEach((ac) => {
                    if (!this.prod.categoryProducts.find(cp => cp.categoryId == ac)) {
                        let catta = this.flatcategories.find(c => c.id == ac);
                        this.addCategoryProduct(catta)
                    }
                })
                this.addingcats = []
                this.showcats = false
            },
            addCategoryProduct(cat) {
                if (cat) {
                    this.prod.categoryProducts.push({
                        id: 0,
                        categoryId: cat.id,
                        productId: this.prod.id,
                        sequence: 1000,
                        category: {
                            id: cat.id,
                            name: cat.name
                        }
                    })
                    console.log(`${cat.name} #${cat.id}`)
                }
            },
            fetchAttributes() {
                axios.get('/api/attributes')
                    .then((response) => {
                        this.attributes = response.data
                        this.attributes.forEach((att, i) => {
                            att.attributeOptions.forEach((ao) => ao.attribute = { id: att.id, name: att.name, label: att.label })
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            addSku() {
                let now = Date.now()
                let sk = {
                    id: 0, name: this.prod.name, code: now,
                    productId: this.prod.id,
                    skuPrices: [{
                        breakquantity: 0, currencyId: 1, id: 0, price: 99,
                        priceListId: 1, skuId: 0
                    }, {
                        breakquantity: 0, currencyId: 1, id: 0, price: 99,
                        priceListId: 2, skuId: 0
                    }],
                    sequence: 1000,
                    createdDate: (new Date()).toJSON(),
                    published: false,
                    status: 1, isDigital: false, isDefault: false, stockType: 0,
                    skuOptions: [], skuProductImages: [], vatSchemeId: 1,
                    packagedWeight: 1,
                    dimensionX: 100,
                    dimensionY: 100,
                    dimensionZ: 100,
                    serviceWorkingDays: 1, priorityDeliveryType: 0,
                    stock: 20,
                    allowBackOrder: false,
                    allowPreOrder: false,
                    removedFromSale: false
                }
                this.prod.skus.push(sk)
            },
            addNewAttributeOptionToAttribute() {
                let nao = this.attributeOptionToCreate
                if (nao) {
                    this.attributeOptionToCreate.option.name = this.attributeOptionToCreate.option.label
                    let nn = Object.assign({}, nao)
                    this.attributeToAdd.attributeOptions.push(nn)
                    this.attributeOptionToCreate = null
                    this.attributeOptionToAdd = nn
                }
            },
            creatNewAttributeOption() {
                if (this.attributeToAdd != null) {
                    let targ = this.attributeToAdd
                    this.attributeOptionToCreate = {
                        attributeId: targ.id, id: 0, sequence: 100,
                        option: {
                            id: 0, name: '**New Option*', label: '**New Option*', isDefault: false, isTheSize: true,
                            optionTypeId: 1, sequence: 1000, useLabel: true
                        },
                        attribute: {
                            label: targ.label, name: targ.name, id: targ.id
                        }
                    }
                    this.attributeOptionToAdd = null
                    this.$nextTick(() => this.$refs[`${this.selectedsku.code}_opt`].select())

                }
            },
            addAttributeOptionToSku() {
                if (this.attributeOptionToAdd && this.selectedsku) {
                    let src = this.attributeOptionToAdd
                    this.selectedsku.skuOptions.push({
                        attributeOption: {
                            id: src.id,
                            attributeId: src.attributeId,
                            attribute: Object.assign({}, src.attribute),
                            sequence: 100,
                            optionId: src.option.id,
                            option: Object.assign({}, src.option),
                        },
                        attributeOptionId: src.id,
                        id: 0,
                        skuId: this.selectedsku.id
                    })
                }
            },
            deleteAttributeOptionFromSku(ao, sk) {
                if (ao.id != 0) {
                    let trg = sk.skuOptions.findIndex(x => x.attributeOptionId == ao.id)
                    sk.skuOptions.splice(trg, 1)
                }
            },
            editskuimages(sku) {
                this.selectedsku = sku
                this.showskuimg = true
                //console.log(imgs)
            },
            deleteskuimage(sku, img) {
                let t = sku.skuProductImages.findIndex(x => x.productImageId == img.id)
                sku.skuProductImages.splice(t, 1)
            },
            addskuimage(sku, img) {
                let n = { id: 0, productImage: img, productImageId: img.id, sequence: img.sequence, skuId: sku.id }
                sku.skuProductImages.push(n)
            },
            cloneproduct(pid) {
                axios.get(`/api/product/clone/${pid}`)
                    .then((response) => {
                        if (response && response.data) {
                            response.data.productPersonalisationSets.forEach(pps => {
                                if (pps.personalisationSet == null) {
                                    let tpps = this.personalisationsets.find(el => el.id == pps.personalisationSetId)
                                    if (tpps)
                                        pps.personalisationSet = tpps
                                }
                            })
                            this.$emit('doSave', response.data)
                            this.prod = response.data
                        }
                        else {
                            console.log('post did not get a response!')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        }
    }
</script>

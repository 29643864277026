<template>
    <div class="product-admin">
        <div class="navbar is-info mb-6">
            <div class="navbar-brand">

            </div>
            <div class="navbar-menu">
                <div class="navbar-start">
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="field is-grouped">
                            <p class="control">
                                <button class="button" @click="newcode">Add New</button>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="container">
            <div v-if="!selectedcode" class="columns is-striped" v-for="(off, i) in promocodes" :key="i">
                <div class="column">
                    <div class="columns">
                        <div class="column is-1">#{{off.id}}</div>
                        <div class="column is-3 has-text-weight-bold">{{off.string}}</div>
                        <div class="column is-6">
                            <strong>{{off.headline}}</strong>
                            <p>
                                {{off.description}}
                            </p>
                            <p>
                                {{off.startDateStr.toLocaleString('en-GB')}} - {{off.endDateStr.toLocaleString('en-GB')}}
                            </p>
                        </div>
                        <div class="column is-1 has-text-right">
                            {{off.totalRedemptions}} uses<br />of {{off.maxRedemptions}}
                        </div>
                        <div class="column is-2"><button @click="selectedcode = off" class="button">Edit</button></div>
                    </div>
                </div>
            </div>
            <div class="columns" v-else>
                <div class="column">
                    <div class="field">
                        <div class="columns">
                            <div class="column">
                                <label class="label">Admin Name</label>
                                <input v-model="selectedcode.name" class="input" />
                            </div>
                            <div class="column">
                                <label class="label">Code</label>
                                <input v-model="selectedcode.string" class="input" />
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="columns">
                            <div class="column is-narrow">
                                <label class="label">Valid From</label>
                                <input :class="{'is-success': dates.validstart, 'is-danger': dates.invalidstart }" type="datetime-local" class="input " v-model="selectedcode.startDate" />
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Until</label>
                                <input :class="{'is-success': dates.validend, 'is-danger': dates.invalidend }" type="datetime-local" class="input " v-model="selectedcode.endDate" />
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Status</label>
                                <div class="select">
                                    <select v-model="selectedcode.status">
                                        <option :value="0">Hidden</option>
                                        <option :value="1">Active</option>
                                        <option :value="-1">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Published</label>
                                <bool-tick v-model="selectedcode.published" />
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Headline</label>
                        <input v-model="selectedcode.headline" class="input" />
                    </div>
                    <div class="field">
                        <div class="columns">
                            <div class="column">
                                <label class="label">Description</label>
                                <textarea class="textarea" v-model="selectedcode.description" rows="2"></textarea>
                            </div>
                            <div class="column">
                                <label class="label">Note</label>
                                <textarea class="textarea" v-model="selectedcode.note" rows="2"></textarea>
                            </div>
                            <div class="column">
                                <label class="label">Guest Note</label>
                                <textarea class="textarea" v-model="selectedcode.guestNote" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="columns">
                            <div class="column is-narrow">
                                <label class="label">Total Redemptions</label>
                                <input v-model="selectedcode.totalRedemptions" type="number" min="0" class="input" />
                            </div>
                            <div class="column is-narrow">
                                <label class="label">Max Redemptions</label>
                                <input v-model="selectedcode.maxRedemptions" type="number" min="1" class="input" />
                            </div>
                        </div>
                    </div>

                </div>
                <footer class="has-background-light">
                    <div class="columns">
                        <div class="buttons">
                            <button @click="selectedcode=null" class="button">Cancel</button>
                            <button @click="savepromocode(selectedcode)" class="button is-primary">Save</button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
        </div>
    </template>
<style>
    .bordered-row {
        border: 1px solid #ddd;
    }

    .bordered-set {
        border-bottom: 4px solid #ebebeb;
        padding: 0.75rem 0;
    }

        .bordered-set:last-child {
            border: none;
        }
</style>

<script>
    import axios from 'axios'
    import BoolTick from './BoolTick.vue'
    /* eslint-disable */
    import YesNoBlob from './YesNoBlob.vue'
    export default {
        props: {

        },
        data() {
            return {
                promocodes: [],
                selectedcode: null,
                filter: 'current'
            }
        },
        emits: [],
        name: "PromoCodeAdmin",
        components: {
            YesNoBlob, BoolTick
        },
        mounted() {
            this.fetchpromocodes()
        },
        computed: {
            dates() {
                if (this.selectedcode) {
                    let now = new Date()
                    let ed = new Date(this.selectedcode.endDate)
                    let sd = new Date(this.selectedcode.startDate)


                    return {
                        validstart: sd < now,
                        validend: ed > now,
                        invalidstart: sd > now,
                        invalidend: ed < now
                    }
                } else return { }
            }
        },
        watch: {
            selectedcode(v) {

            }
        },
        methods: {
            fetchpromocodes() {
                axios.get('api/offers/promocodes')
                    .then((response) => {
                        this.promocodes = response.data;
                        this.promocodes.forEach(v => {
                            v.startDateStr = new Date(v.startDate)
                            v.endDateStr = new Date(v.endDate)
                        })                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            newcode() {
                axios.get('api/offers/promocodes/new')
                    .then((response) => {
                        this.selectedcode = response.data;
                        this.selectedcode.startDate = this.selectedcode.startDate.substr(0, 19)
                        this.selectedcode.endDate = this.selectedcode.endDate.substr(0, 19)
                        this.selectedcode.startDateStr = new Date(this.selectedcode.startDate)
                        this.selectedcode.endDateStr = new Date(this.selectedcode.endDate)
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            savepromocode(code) {
                axios.post('api/offers/promocodes', code)
                    .then((response) => {
                        if (response && response.data) {
                            this.selectedcode = null
                            this.fetchpromocodes()
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
</script>
<template>
    <div class="product-admin">
        <div class="container cat-admin">
        <div class="columns is-stretch">
            <div class="column">
                <div class="container">
                    <div class="content">
                        <h1>Categories</h1>
                    </div>
                    <ul class="content scrolly-cats">
                        <li class="" v-for="(c,i) in categories.sort((a,b) => a.sequence - b.sequence)" :key="i">
                            <div class="field has-addons">
                                <div class="control">
                                    <input @click="selected = c" type="text" class="input is-small" v-model="c.name" />
                                </div>
                                <div class="control">
                                <a title="Add Child Category" @click="addcategory(c)" class="button is-small">
                                    &#8626;
                                </a>
                                </div>
                            </div>

                            <ul v-if="c.children.length">
                                <li v-for="(cc,i) in c.children.sort((a,b) => a.sequence - b.sequence)" :key="i" >
                                    <div class="field has-addons">
                                        <div class="control">
                                            <input @click="selected = cc" type="text" class="input is-small" v-model="cc.name" />
                                        </div>

                                        <div class="control">
                                        <a title="Add Child Category" @click="addcategory(cc)" class="button is-small">&#8626;</a>
                                        </div>
                                    </div>
                                    <ul v-if="cc.children.length">
                                        <li class="" v-for="(ccc,i) in cc.children.sort((a,b) => a.sequence - b.sequence)" :key="i" >
                                            <div class="field has-addons">
                                                <div class="control">
                                                    <input @click="selected = ccc" type="text" class="input is-small" v-model="ccc.name" />
                                                </div>
                                                <div class="control">
                                                <a title="Add Child Category" @click="addcategory(ccc)" class="button is-small">
                                                    &#8626;
                                                </a>
                                                </div>
                                            </div>
                                            <ul v-if="ccc.children.length">
                                                <li v-for="(cccc,i) in ccc.children.sort((a,b) => a.sequence - b.sequence)" :key="i" >
                                                    <div class="field has-addons">
                                                        <div class="control">
                                                            <input  @click="selected=cccc" type="text" class="input is-small" v-model="cccc.name" />
                                                        </div>
                                                        <div class="control">
                                                        <a title="Add Child Category" class="button is-disabled is-small">
                                                            &#8626;
                                                        </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="column">
                <div class="cat-editor" v-if="selected != null">
                    <div class="field">
                        <label class="label">Name #{{selected.id}}</label>
                        <input @keyup="makeslug" v-model="selected.name" type="text" class="input" />
                    </div>
                    <div class="field">
                        <label class="label">Slug</label>
                        <input v-model="selected.slug" type="text" class="input" />
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Sequence</label>
                                <input v-model="selected.sequence" type="number" step="10" class="input" />
                            </div>

                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Status</label>
                                <div class="select">
                                    <select v-model="selected.status">
                                        <option value="1">Active</option>
                                        <option value="0">Hidden</option>
                                        <option value="-1">Deleted</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Parent</label>
                                <div class="select">
                                    <select v-model="selected.parentId">
                                        <option :value="null">Top Level</option>
                                        <option v-for="c in flatcategories" :value="c.id">{{c.name}}</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Depth</label>
                                <input v-model="selected.depth" type="number" step="10" class="input" />
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Description</label>
                        <textarea class="input" v-model="selected.description"></textarea>
                    </div>

                    <div class="field">
                        <label class="label">Show In Primary Menu <input v-model="selected.inPrimaryNav" type="checkbox" value="true" /></label>
                        
                    </div>
                    <div class="columns">
                        <div class="field column">
                            <label class="label">In Nav On Date</label>
                            <input v-model="selected.inPrimaryNavOnDate" type="datetime-local" class="input" />
                        </div>
                        <div class="field column">
                            <label class="label">In Nav Off Date</label>
                            <input v-model="selected.inPrimaryNavOffDate" type="datetime-local" class="input" />
                        </div>
                    </div>


                    <div class="field">
                        <label class="label">SEO Title</label>
                        <textarea class="input" v-model="selected.seoTitle"></textarea>
                    </div>
                    <div class="field">
                        <label class="label">SEO Description</label>
                        <textarea class="input" v-model="selected.seoDescription"></textarea>
                    </div>
                    <div class="field">
                        <label class="label">Alias URLs</label>
                        <div v-for="a in selected.aliasCategories">/{{a.value}}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <footer v-if="selected != null" class="has-background-light">
            <div class="columns">
                <div class="buttons">
                    <button class="button" @click="selected = null">Cancel</button>
                    <button class="button is-primary" @click="savecat">SAVE</button>
                </div>
            </div>
        </footer>
    </div>
</template>
<style>
    .is-stretch { align-items: stretch; }
    .cat-editor {  top: 7rem; left: 50%; right: 2rem; position:sticky; }
    .is-sticky {position: sticky;}
    .cat-admin { margin-top: 2rem; }
    .scrolly-cats { height: calc(100vh - 16rem); overflow: auto; }
</style>
<script>
    import axios from 'axios'
    export default {
        name: "CategoryAdmin",
        props: {},
        computed: {
            flatcategories() {
                let fla = []
                if(this.categories && this.categories.length)
                    this.categories.forEach((v) => {
                        fla.push(v)
                        v.children.forEach((c) => {
                            fla.push(c)
                            c.children.forEach((cc) => {
                                fla.push(cc)
                                cc.children.forEach((ccc) => {
                                    fla.push(ccc)
                                    ccc.children.forEach((cccc) => {
                                        fla.push(cccc)
                                    })
                                })
                            })
                        })
                    })
                return fla
            }
        },
        data() {
            return {
                categories: [],
                selected: null

            }
        },
        mounted() {
            this.fetchcategories()
        },
        methods: {
            addcategory(parent) {
                let nc = {
                    id: 0,
                    name: 'New Category',
                    depth: parent.depth+1,
                    slug: 'new-category',
                    sequence: +parent.sequence + 10,
                    parentId: parent.id,
                    parent: parent,
                    status: 1,
                    children: [],
                    description: null,
                    seoDescription: null,
                    seoTitle: null
                }
                parent.children.push(nc)
                this.selected = nc
            },
            makeslug() {
                if(this.selected && this.selected.id == 0)
                    this.selected.slug = this.selected.name.replace('&', 'and').replace(/[^a-z0-9 ]+/gi, '').replace(/[ ]+/gi, '-').toLowerCase()

            },
            savecat() {
                //let that = this
                let data = Object.assign({}, this.selected)
                data.parent = null
                data.children = null
                axios.post('api/category', data)
                    .then((response) => {
                        if (response && response.data) {
                            this.selected = response.data
                        } else
                            console.log(response)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchcategories() {
                axios.get('api/category')
                    .then((response) => {
                        this.categories = response.data

                        this.categories.forEach((c) => {
                            c.children.forEach((cc) => {
                                cc.parent = c
                                cc.children.forEach((ccc) => {
                                    ccc.parent = cc
                                    ccc.children.forEach((cccc) => {
                                        cccc.parent = ccc
                                    })
                                })
                            })
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
</script>